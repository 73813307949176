<ng-container>
  <div class="passenger-count">
    <span translate>Passengers to be checked-in</span>
    <ng-container *ngIf="isJourneyValidForCheckin()">
      <button
        *ngIf="
          (selectPassengersInCheckinSelections$ | async)?.length !==
          passengers.length
        "
        class="navitaire-digital-button primary-transparent select-all-button"
        [disabled]="
          checkedInPassengerKeys?.length > 0 ||
          passengerKeysSeatedInRestrictedRows?.length > 0 ||
          boardedPassengerKeys?.length > 0
        "
        [class.disabled]="
          checkedInPassengerKeys?.length > 0 ||
          passengerKeysSeatedInRestrictedRows?.length > 0 ||
          boardedPassengerKeys?.length > 0
        "
        (click)="addAllPassengerToCheckinSelection()"
        translate
      >
        Select All
      </button>
      <span
        *ngIf="
          (selectPassengersInCheckinSelections$ | async)?.length ===
          passengers.length
        "
        class="cancel-all-button"
        (click)="removeAllPassengerToCheckinSelection()"
      translate>
        Cancel
      </span>
    </ng-container>
  </div>

  <div class="checkin-card-container">
    <navitaire-digital-passenger-list
      class="passenger-list"
      [passengers]="passengers"
      [showTravelDocs]="true"
      [showCheckBox]="isJourneyValidForCheckin()"
      [passengerKeysCheckinSelections]="
        selectPassengersInCheckinSelections$ | async
      "
      [passengerKeysSeatedInRestrictedRows]="
        passengerKeysSeatedInRestrictedRows
      "
      [checkedInPassengerKeys]="checkedInPassengerKeys"
      [boardedPassengerKeys]="boardedPassengerKeys"
      (passengerKeyEmitter)="addPassengerToCheckinSelection($event, true)"
      (boardingPassEmitter)="reprintBoardingPass($event)"
    ></navitaire-digital-passenger-list>
  </div>
</ng-container>
