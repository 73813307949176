import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  BookingDataService,
  NgBooking_journeysClientService,
  NgBooking_ssrsClientService,
  NskSessionSelectors,
  SsrDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { catchError, map, Observable, of } from 'rxjs';
import { INSURANCE_API_URL } from '../../config/injection.tokens';
import { InsuranceData } from './models/insurance-response.model';

@Injectable({ providedIn: 'root' })
export class QGSsrDataService extends SsrDataService {
  constructor(
    protected bookingSsrClient: NgBooking_ssrsClientService,
    protected bookingJourneysClient: NgBooking_journeysClientService,
    protected bookingDataService: BookingDataService,
    protected store: Store,
    protected http: HttpClient,
    @Inject(INSURANCE_API_URL) protected insuranceApiUrl: string
  ) {
    super(bookingSsrClient, bookingJourneysClient, bookingDataService, store);
  }

  getInsurancePrices(
    isInternational: boolean = false
  ): Observable<InsuranceData[]> {
    const token = getObservableValueSync(
      this.store.select(NskSessionSelectors.selectSessionContext)
    );

    if (token) {
      const body = new HttpParams()
        .set('signature', token?.serverContext?.token)
        .set('isInternational', isInternational);

      return this.http
        .post<any>(this.insuranceApiUrl, body.toString(), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .pipe(
          catchError(e => {
            console.log(`Insurance API error: ${e}`);
            return of(null);
          }),
          map(r => {
            return r?.Data?.DataIns;
          })
        );
    }

    return null;
  }
}
