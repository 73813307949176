import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AvailableJourney,
  checkJourneyForExternalCarrier,
  checkJourneyForMultipleCarriers,
  FlightOperationalAttribute,
  Journey,
  Leg
} from '@navitaire-digital/nsk-api-4.5.0';
import { SeatDataService } from '@navitaire-digital/web-data-4.5.0';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { PageBusyService } from '../../common/page-busy.service';
import { ExtrasManagerStore } from '../../extras/extras-manager/extras-manager-component.store';

@Component({
  selector: 'navitaire-digital-flight-details',
  templateUrl: './flight-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['flight-details.scss']
})
export class FlightDetailsComponent implements OnInit, OnDestroy {
  @Input()
  fareClass: string;
  @Input() set journey(journey: AvailableJourney | Journey) {
    this._journey = journey;
    this.isCodeshare = checkJourneyForExternalCarrier(journey);
    this.isInterline = checkJourneyForMultipleCarriers(journey);
    this.allowFlightDetails =
      journey?.segments?.length > 1 ||
      journey?.segments[0]?.legs?.length > 1 ||
      checkJourneyForExternalCarrier(journey);
  }
  get journey(): AvailableJourney | Journey {
    return this._journey;
  }
  @Input()
  moreDetailsOption: boolean = true;
  @Input()
  flightStatus?: FlightOperationalAttribute;
  @Input()
  departureTime?: string;
  @Input()
  arrivalTime?: string;
  @Input()
  showConnectionDetails?: boolean = true;
  @Input()
  isCompactViewEnabled?: boolean = false;
  @Output()
  expandDetails: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('StatelessSeatMapDialog')
  statelessSeatMapDialog: ElementRef;
  seatMapReference: string;
  selectedLeg: Leg;
  FlightStatus: typeof FlightOperationalAttribute = FlightOperationalAttribute;
  /** Has segment where marketing and operating airlines differ */
  isCodeshare: boolean = false;
  /** At least two segments with different airlines */
  isInterline: boolean = false;
  _extrasManager: ExtrasManagerStore;
  _journey: AvailableJourney | Journey;
  unsubscribe$ = new Subject<void>();
  allowFlightDetails: boolean = false;
  showFlightDetails: boolean = false;
  seatSelectionUnavailable: boolean = false;
  constructor(
    @Optional() extrasManager: ExtrasManagerStore,
    protected pageBusyService: PageBusyService,
    protected seatDataService: SeatDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected overlay: Overlay
  ) {
    if (extrasManager) {
      this._extrasManager = extrasManager;
    }
  }

  ngOnInit(): void {
    if (this._extrasManager) {
      this._extrasManager.selectSelectedJourneyKey$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          if (this.showFlightDetails) {
            this.expandFlightDetails(false);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  expandFlightDetails(value: boolean): void {
    this.showFlightDetails = value;
    this.expandDetails.emit(value);
  }

  async showSeatsOverlay(leg: any): Promise<void> {
    this.selectedLeg = leg;
    this.pageBusyService.showLoadingSpinner();

    try {
      await this.seatDataService.loadSeatMapsForJourney(
        this.journey.journeyKey
      );
      this.seatMapReference = leg.seatmapReference;

      this.pageBusyService.hideLoadingSpinner();

      const config = new OverlayConfig({
        positionStrategy: this.overlay.position().global(),
        hasBackdrop: true,
        panelClass: ['popup', 'fare-select-popup'],
        backdropClass: 'popup-backdrop',
        scrollStrategy: this.overlay.scrollStrategies.block()
      });

      this.overlayService.showPopup(this.statelessSeatMapDialog, config);
    } catch {
      this.pageBusyService.hideLoadingSpinner();
      this.seatSelectionUnavailable = true;
    }
  }

  closeDialog(): void {
    this.overlayService.hide();
  }
}
