import { CheckinPassengerRestrictionType } from '@navitaire-digital/nsk-api-4.5.0';
import { CdkConfiguration, InsurancePurposeFor, InsuranceSsrPriceType } from '@customer/components';

export const cdkConfiguration: CdkConfiguration = {
  flightStatusCountLimit: 10,
  passengersConfig: {
    infantName: 'Infant',
    maximumCount: 9,
    passengers: [
      {
        allowedToHaveInfant: true,
        isRequired: true,
        passengerTypeCode: 'ADT',
        count: 1
      },
      {
        passengerTypeCode: 'CHD',
        isRequired: false,
        allowedToHaveInfant: false,
        count: 0
      }
    ],
    infantType: 'LAP',
    passengerTypes: {
      ADT: {
        passengerTypeCode: 'ADT',
        titleSingular: 'Adult',
        titlePlural: 'Adults',
        minAge: 12,
        availableTitlesByWeightCategory: [1, 2]
      },
      CHD: {
        passengerTypeCode: 'CHD',
        titleSingular: 'Child',
        titlePlural: 'Children',
        maxAge: 11,
        availableTitlesByWeightCategory: [3]
      },
      LAP: {
        passengerTypeCode: 'INF',
        titleSingular: 'Infant',
        titlePlural: 'Infants',
        minAge: 0,
        // Override display configuration (no config available for infants)
        ageInfo: 'Under 2 Years',
        // Override validation (no configuration is available for lap infants)
        maxAge: 2
      }
    },
    relationships: [
      'Child',
      'Parent',
      'Guardian',
      'Spouse',
      'Relative',
      'Friend',
      'Others'
    ]
  },
  flow: {
    flowConfig: {
      home: ['search'],
      booking: ['select', 'passengers', 'extras', 'payment'],
      checkin: ['review', 'success'],
      mytrips: ['select', 'search'],
      manage: [
        'hub',
        'seats',
        'bags',
        'meals',
        'insurance',
        'citiWrapping',
        'hotel',
        'flight',
        'payment',
        'itinerary',
        'selfServe'
      ],
      flightstatus: ['results'],
      error: ['global'],
      'flexible-shopping': [
        'select',
        'passengers',
        'extras',
        'payment',
        'itinerary'
      ]
    },
    breadCrumbNames: {
      booking: {
        Select: 'Flight'
      },
      'flexible-shopping': {
        select: 'flight'
      }
    }
  },
  faresConfig: [
    {
      key: 'economy',
      order: 0,
      displayName: 'Basic',
      productClass: 'NR'
    }
  ],

  lowFare: {
    maxMonthsAllowed: 12,
    includeTaxesAndFees: true
  },
  ssrs: {
    seatFeeCodes: ['SEAT'],
    seatSSRCodes: ['GZN', 'ATGZ', 'RZN', 'ATRZ'],
    exclude: ['FB05', 'FB10', 'FB20', 'FB30', 'FB40', 'ATBG'],
    bags: {
      carryOn: ['COB1'],
      checked: ['XB05', 'XB10', 'XB20', 'XB30', 'XB40'],
      personalItem: ['PERS']
      // Sandbox 2 Bag ssrs
      // carryOn: ['DCO'],
      // personalItem: ['DPER'],
      // checked: ['D1BG', 'D2BG', 'D3BG']
    },
    meals: {
      mealsPerPersonPerLeg: 5,
      discountFeeCode: 'HM00'
    },
    surpriseCodes: {
      ssrCodes: ['SID1', 'SID2'],
      feeCodes: ['SID']
    },
    passengerSsrCodes: ['PETC', 'WCHR'],
    additionalSsrsCodes: ['PRIO'],
    insurances: {
      ssrCodes: ['SBG', 'SPL', 'PPL', 'SSD'],
      priceConfig: [
        {
          ssrCode: 'SBG',
          type: InsuranceSsrPriceType.JOURNEY,
          purpose: InsurancePurposeFor.BAG
        },
        {
          ssrCode: 'SPL',
          type: InsuranceSsrPriceType.JOURNEY,
          purpose: InsurancePurposeFor.PAX
        },
        {
          ssrCode: 'PPL',
          type: InsuranceSsrPriceType.JOURNEY,
          purpose: InsurancePurposeFor.PAX
        },
        {
          ssrCode: 'SSD',
          type: InsuranceSsrPriceType.TRIP,
          purpose: InsurancePurposeFor.PAX
        }
      ]
    },
    lounges: {
      ssrCodes: ['SPPL', 'APHL', 'LGA', 'LHLP', 'APSL', 'BSL', 'SBSL']
    },
    wrappers: {
      ssrCodes: ['WRPZ']
    },
    passengerServices: {
      ssrCodes: ['PSPA', 'PSPD', 'PSJA', 'PSJD', 'BPAD', 'BAAD', 'BPAA', 'BAAA'],
      feeCodes: ['PSJ', 'PSPA', 'PSPD', 'PSJA', 'PSJD', 'BPAD', 'BAAD', 'BPAA', 'BAAA'],
      departureSsrCodes: ['PSPD', 'PSJD', 'BPAD', 'BAAD'],
      arrivalSsrCodes: ['PSPA', 'PSJA', 'BPAA', 'BAAA'],
      imagePassenggerService : {
        ['PSPA']: 'PASS',
        ['PSPD']: 'PASS',
        ['PSJA']: 'JOUMPA',
        ['PSJD']: 'JOUMPA',
        ['BPAD']: 'BLUE',
        ['BAAD']: 'BLUE',
        ['BPAA']: 'BLUE',
        ['BAAA']: 'BLUE'
      }
    },
    infantFeeCode: 'INF'
  },

  checkedBagsConfig: {
    checkedBags: [
      {
        checkedBagCode: 'XB05',
        allowance: 5
      },
      {
        checkedBagCode: 'XB10',
        allowance: 10
      },
      {
        checkedBagCode: 'XB20',
        allowance: 20
      },
      {
        checkedBagCode: 'XB30',
        allowance: 30
      },
      {
        checkedBagCode: 'XB40',
        allowance: 40
      }
    ]
  },

  flightSelect: {
    domesticOverlapBufferHours: 0.5,
    internationalOverlapBufferHours: 1
  },
  seatmapUnitConfig: {
    defaultHeight: '1fr',
    defaultWidth: '0.5fr'
  },
  refundConfig: {
    // Note this can be configured via API configuration
    // see 'paymentOptions DefaultRefundCreditShellCode'
    accountTransactionCode: 'CXL',
    maximumTimeSincePurchase: 24,
    minimumTimeUntilDeparture: 168
  },
  paymentMethodConfig: {
    voucherCode: 'VO',
    customerCreditCode: 'CF',
    agencyAccount: 'AG',
    cash: 'CA',
    googlePay: 'GP',
    applePay: 'AP',
    loyaltyCode: 'LY',
    espayCommCode: 'SGWCITILINK02'
  },
  paymentGroups: [
    {
      name: 'Other Payment Methods',
      paymentMethods: [
        {
          enabled: false,
          displayName: 'Vouchers',
          paymentCode: 'VO',
          iconClass: 'logo_voucher',
          allowedForPartialPayment: true
        }
      ]
    },
    {
      name: 'Instant Payment',
      paymentMethods: [
        {
          enabled: false,
          displayName: 'ShopeePay',
          paymentCode: 'SP',
          useExternalPaymentLink: true,
          bankCode: '008',
          productCode: 'SHOPEEJUMPPAY',
          iconClass: 'logo_shopeepay',
          feeCode: 'BTV',
          allowedForPartialPayment: true
        },
        {
          enabled: false,
          displayName: 'LinkAja',
          paymentCode: 'LE',
          useExternalPaymentLink: true,
          bankCode: '008',
          productCode: 'LINKAJAAPPLINK',
          iconClass: 'logo_linkaja',
          allowedForPartialPayment: true,
          feeCode: 'BTV'
        },
        {
          enabled: true,
          displayName: 'OVO',
          paymentCode: 'OV',
          useExternalPaymentLink: true,
          bankCode: '503',
          productCode: 'OVO',
          iconClass: 'logo_ovo',
          allowedForPartialPayment: true,
          feeCode: 'BTV'
        },
        {
          enabled: false,
          displayName: 'Indodana',
          paymentCode: 'IO',
          useExternalPaymentLink: true,
          bankCode: '014',
          productCode: 'INDODANA',
          iconClass: 'logo_indodana',
          allowedForPartialPayment: true,
          feeCode: 'PYL'
        },
        {
          enabled: true,
          displayName: 'BCA Virtual Account',
          paymentCode: 'VU',
          useExternalPaymentLink: true,
          bankCode: '014',
          productCode: 'BCAATM',
          iconClass: 'logo_bca',
          allowedForPartialPayment: true,
          feeCode: 'BTV'
        }
      ]
    },
    {
      name: 'Credit',
      paymentMethods: [
        {
          enabled: true,
          displayName: 'Credit Card',
          paymentCode: 'CE',
          useExternalPaymentLink: true,
          bankCode: '008',
          productCode: 'CREDITCARD',
          iconClass: 'logo_credit_card',
          feeCode: 'CCS',
          allowedForPartialPayment: true
        }
      ]
    },
    {
      name: 'Retails',
      disableOnMb: true,
      paymentMethods: [
        {
          enabled: true,
          displayName: 'Indomaret',
          iconClass: 'logo_indomaret',
          allowedForPartialPayment: false
        },
        {
          enabled: true,
          displayName: 'Alfamart',
          iconClass: 'logo_alfamart',
          allowedForPartialPayment: false
        },
        {
          enabled: true,
          displayName: 'Alfamidi',
          iconClass: 'logo_alfamidi',
          allowedForPartialPayment: false
        }
      ]
    },
    {
      name: 'Transfers',
      disableOnMb: true,
      paymentMethods: [
        {
          enabled: true,
          displayName: 'ATM BNI',
          iconClass: 'logo_bni',
          allowedForPartialPayment: false
        },
        {
          enabled: true,
          displayName: 'ATM BRI',
          iconClass: 'logo_bri',
          allowedForPartialPayment: false
        },
        {
          enabled: true,
          displayName: 'ATM BCA',
          iconClass: 'logo_bca',
          allowedForPartialPayment: false
        },
        {
          enabled: true,
          displayName: 'ATM Mandiri',
          iconClass: 'logo_mandiri',
          allowedForPartialPayment: false
        }
      ]
    }
  ],
  myTripsOperationsWindow: 48,
  webOrganizationCodes: ['MK', 'NV', 'SYSTEM'],
  multiCityConfig: {
    showMultiCityTripType: true,
    multiCityMaxJourneyLimit: 4
  },
  checkinConfig: {
    allowedPassengerRestrictions: [
      CheckinPassengerRestrictionType.MissingDocuments,
      CheckinPassengerRestrictionType.MissingCountryOrResidence,
      CheckinPassengerRestrictionType.MissingDestinationAddress
    ],
    allowTravelDocuments: true,
    allowDestinationAddresses: true
  },
  selfServeConfig: {
    daysBeforeOrAfter: 1
  },
  googlePayConfig: {
    paymentOptions: {
      environment: 'TEST',
      merchantInfo: {
        merchantId: '01058342713341955732',
        merchantName: 'Navitaire'
      }
    },
    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    allowedCardNetworks: ['AMEX', 'VISA', 'MASTERCARD'],
    paymentGatewayTokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'adyen',
        gatewayMerchantId: 'NavitaireCOM'
      }
    },
    apiVersion: 2,
    apiVersionMinor: 0
  },
  applePayConfig: {
    merchantIdentifier: 'merchant.com.adyen.navitaire.digital.test',
    merchantName: 'Navitaire',
    supportedNetworks: ['visa', 'masterCard', 'amex'],
    merchantCapabilities: ['supports3DS'],
    version: 3
  },
  applicationName: 'IBE',
  bundleConfig: {
    bundleEnabled: true,
    bundleFaresConfig: [
      {
        key: 'economy',
        order: 0,
        displayName: 'Basic',
        productClass: 'NR'
      }
    ],
    bundleCodes: ['BPS', 'BPSI', 'BPP', 'BPPI'],
    bundleInclusions: [
      {
        bundleCode: 'BPS',
        baggageWeight: 20,
        inclusion: [
          {
            icon: 'bag',
            text: 'schedulepage.bundle.eco.BagCabin'
          },
          {
            icon: 'bag',
            text: 'schedulepage.bundle.eco.BagNote'
          },
          {
            icon: 'ticket',
            text: 'schedulepage.bundle.eco.Ticket'
          },
          {
            icon: 'seat',
            text: 'schedulepage.bundle.eco.seat'
          }
        ],
        freeBaggageInfo: '7kg Cabin baggage and 20kg Check-in baggage'
      },
      {
        bundleCode: 'BPSI',
        baggageWeight: 20,
        inclusion: [
          {
            icon: 'bag',
            text: 'schedulepage.bundle.eco.BagCabin'
          },
          {
            icon: 'bag',
            text: 'schedulepage.bundle.eco.BagNote'
          },
          {
            icon: 'ticket',
            text: 'schedulepage.bundle.eco.Ticket'
          }
        ],
        freeBaggageInfo: '7kg Cabin baggage and 20kg Check-in baggage'
      },
      {
        bundleCode: 'BPP',
        baggageWeight: 20,
        inclusion: [
          {
            icon: 'bag',
            text: 'schedulepage.bundle.plus.BagCabin'
          },
          {
            icon: 'bag',
            text: 'schedulepage.bundle.plus.BagNote'
          },
          {
            icon: 'ticket',
            text: 'schedulepage.bundle.plus.Ticket'
          },
          {
            icon: 'seat',
            text: 'schedulepage.bundle.plus.seat'
          },
          {
            icon: 'meal',
            text: 'schedulepage.bundle.plus.meals'
          }
        ],
        freeBaggageInfo: '7kg Cabin baggage and 20kg Check-in baggage'
      },
      {
        bundleCode: 'BPPI',
        baggageWeight: 20,
        inclusion: [
          {
            icon: 'bag',
            text: 'schedulepage.bundle.plus.BagCabin'
          },
          {
            icon: 'bag',
            text: 'schedulepage.bundle.plus.BagNote'
          },
          {
            icon: 'ticket',
            text: 'schedulepage.bundle.plus.Ticket'
          },
          {
            icon: 'meal',
            text: 'schedulepage.bundle.plus.meals'
          }
        ],
        freeBaggageInfo: '7kg Cabin baggage and 20kg Check-in baggage'
      }
    ]
  },

  notificationConfig: {
    notificationsEnabled: true,
    NotificationDestinations: {
      emailEnabled: true,
      smsEnabled: true
    }
  },

  FrequentFlyerConfig: {
    documentTypeCode: 'OAFF',
    carrierCode: 'QG'
  },

  bookingSummaryConfig: {
    passengerTypes: ['Adult', 'Child', 'Infant'],
    subTotal: 'Subtotal'
  },

  travelDocumentsConfig: {
    travelDocuments: {
      Passport: {
        documentTypeCode: 'P'
      },
      KTP: {
        documentTypeCode: 'KTP'
      },
      StudentID: {
        documentTypeCode: 'STU'
      }
    }
  },

  AvailableTravelDocumentsConfig: {
    travelDocuments: ['P', 'KTP', 'STU']
  },

  DefaultIssuingCountryConfig: {
    countryCode: 'ID'
  },

  // restrictedSeatRows: ['11', '12', '14', '15'],
  // restrictedSeatRowsATR: ['1','2'],
  restrictedSeatRows: {['320']:['11', '12', '14', '15'], ['ATR']:['1','2']},

  equipmentBasedConfig: {
    equipment: [
      {
        type: '320',
        row: ['1', '2', '3', '4', '5', '12', '13', '14'],
        seatSsrCode: 'GZN'
      },
      {
        type: '320',
        row: ['6', '7', '8', '9', '10', '11', '15', '16', '17', '18', '19',
        '20','21','22','23','24','25','26','27','28','29','30','31'],
        seatSsrCode: 'RZN'
      },
      {
        type: 'ATR',
        row: ['1', '16', '17', '18', '19'],
        seatSsrCode: 'ATGZ',
        bagSsrCode: 'ATBG',
      },
      {
        type: 'ATR',
        row: ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
        seatSsrCode: 'ATRZ',
        bagSsrCode: 'ATBG',
      }
    ],
    baggageCodes: ['ATBG'],
    seatCodes: ['GZN', 'RZN', 'ATGZ', 'ATRZ'],
  },

  baseFareCode: 'XX - FAREONLY',
};
