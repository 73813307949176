import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { SsrByKeysRequestv2 } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  NskLocalizationSelectors,
  SsrDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppBookingFlowActions } from '../../../analytics/actions/booking-flow/app-booking-flow.actions';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';

@Component({
  selector: 'navitaire-digital-select-meals-page',
  templateUrl: './select-meals-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['select-meals.scss']
})
export class SelectMealsPageComponent implements OnInit, OnDestroy {
  isLastLeg: boolean;
  unsubscribe$ = new Subject<void>();

  hasOneJourney$: Observable<boolean> =
    this.extrasManagerStore.selectHasOneJourney$;

  analyticsUnsubscribe$ = new Subject<void>();

  isManageFlow: boolean = getObservableValueSync(
    this.store.select(BookingSelectors.selectIsBookingCommitted)
  );

  constructor(
    protected extrasManagerStore: ExtrasManagerStore,

    protected location: Location,
    protected ssrDataService: SsrDataService,
    protected store: Store,
    protected router: Router
  ) {}

  ngOnInit(): void {
    this.extrasManagerStore.selectIsLastLegKeySelected$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.isLastLeg = value;
      });
  }

  /**
   * Method to sell meals
   * Checks for insurance when meal changes exist
   * Sells and/or deletes any meal changes
   * Navigates back to extras main page
   */
  async sellMeals(): Promise<void> {
    const hasMealChanges = getObservableValueSync(
      this.extrasManagerStore.selectHasMealChanges$
    );

    if (!hasMealChanges) {
      this.location.back();
    } else {
      await this.sellMealSelection();
      this.location.back();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async sellMealSelection(): Promise<void> {
    const mealChanges = getObservableValueSync(
      this.extrasManagerStore.selectMealChanges$
    );

    if (mealChanges.delete.length > 0) {
      await this.ssrDataService.removeSsrs(mealChanges.delete);
    }
    // Only Sell
    if (mealChanges.sell.length > 0) {
      const currencyCode = getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
        )
      );

      const request: SsrByKeysRequestv2 = {
        currencyCode: currencyCode,
        keys: mealChanges.sell.map(k => ({
          count: 1,
          ssrKey: k
        }))
      };
      await this.ssrDataService.sellSSrs(request);
    }

    this.store.dispatch(AppBookingFlowActions.mealchanges(mealChanges));
  }

  moveToNextLeg(): void {
    this.extrasManagerStore.moveToFirstPassenger();
    this.extrasManagerStore.moveToNextLeg();
  }
}
