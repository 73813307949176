import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  asPromise,
  CheckinRequirements,
  Journey,
  LiftStatus,
  Passenger
} from '@navitaire-digital/nsk-api-4.5.0';
import { CheckinSelection, TripDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { ModalComponent } from '../../cms/cms-components/modal/modal.component';
import { slide } from '../../common/animations';
import { PageBusyService } from '../../common/page-busy.service';
import { selectAllowTravelDocs, selectRestrictedSeatRowsConfig } from '../../config/selectors';
import { CheckinRequirementsService } from '../services/checkin-requirements.service';
import { CheckinPassengerFormComponent } from './checkin-passenger-form/checkin-passenger-form.component';
import { some } from 'lodash';
import { ClearBoardingPasses, NskCheckinSelectors, SetBoardingPasses } from '../../store';
import { FlowManagerService } from '../../app-state/flow-manager.service';
import { Router } from '@angular/router';
import { CompleteCheckinAction } from '../../analytics';
import { QGCheckinDataService } from '@customer/extensions';

/**
 * Component used to display list of passengers to be checked in
 * Also displayes CheckinPassengerFormComponent when check in requirements are yet valid
 */
@Component({
  selector: 'navitaire-digital-checkin-passengers',
  templateUrl: './checkin-passengers.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [slide],
  styleUrls: ['checkin-passengers.scss']
})
export class CheckinPassengersComponent implements OnInit, OnDestroy {
  /** Checkin requirements */
  checkinRequirements$: Observable<CheckinRequirements> =
    this.checkinDataService.checkinRequirements$;

  /** Initial validity state of checkin requirements */
  initiallyValid: boolean;

  /** Boolean for if checkin requirements are complete */
  checkinComplete: boolean = false;

  /** Boolean for if adding travel documents is allowed */
  allowTravelDocuments: boolean = getObservableValueSync(
    this.store.select(selectAllowTravelDocs)
  );

  /** This holds value for checkin selections */
  selectCheckinSelections$ = this.store.select(NskCheckinSelectors.selectCheckinSelections);

  /** This maps the passenger key from checkin selections.
   *  It only returns the passengers key of the selections
   */
  selectPassengersInCheckinSelections$ = this.selectCheckinSelections$.pipe(
    map(selections => selections?.filter(selection => selection?.journeyKey === this.journey?.journeyKey)
      .map(selection => selection?.passengerKey))
  )

  selectRestrictedSeatRowsConfig = getObservableValueSync(this.store.select(selectRestrictedSeatRowsConfig));
  // selectRestrictedSeatRowsATRConfig = this.store.select(selectRestrictedSeatRowsATRConfig)

  /** Number of passengers to be checked in */
  passengersLength: number;

  /** List of passengers to be checked in */
  passengers: Passenger[];

  /** Active passenger index */
  activePassengerIndex: number = 0;
  /** Active passenger as an array to animate */
  activePassenger: Passenger[] = [];

  /** Checked-in passenger passenger keys */
  checkedInPassengerKeys: string[];
  /** Passengers seated in restricted rows */
  passengerKeysSeatedInRestrictedRows: string[];
  /** Passengers seated in boarded rows */
  boardedPassengerKeys: string[];
  /** Active passenger index behavior subject */
  _activePassengerIndex: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  bookingJourneys$: Observable<Journey[]> = this.store.select(NskCheckinSelectors.selectAllJourneys);

  // Component destroyed subject
  unsubscribe$ = new Subject<void>();

  @ViewChild('travelDocErrorModal')
  travelDocErrorModal: ModalComponent;

  /** Query list of passenger checkin forms */
  @ViewChildren(CheckinPassengerFormComponent, {
    read: CheckinPassengerFormComponent
  })
  passengerForms: QueryList<CheckinPassengerFormComponent>;

  /** Current passengers checkin form */
  get currentPassengerForm(): CheckinPassengerFormComponent {
    return this.passengerForms.first;
  }

  /** Holds the journey */
  @Input() journey: Journey;

  /** Holds value if journey is checkinable */
  @Input() isJourneyCheckinable: boolean;

  /** Holds value if journey is international */
  @Input() isInternational: boolean;

  /** Emits when checkinRquirements are valid */
  @Output() checkinRequirementsComplete: EventEmitter<void> =
    new EventEmitter<void>();

  /** Emits when checkin fails and should bed direct home */
  @Output() startOver: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected checkinDataService: QGCheckinDataService,
    protected tripDataService: TripDataService,
    protected checkinRequirementsService: CheckinRequirementsService,
    protected pageBusyService: PageBusyService,
    protected store: Store,
    protected changeDetectorRef: ChangeDetectorRef,
    protected flowManagerService: FlowManagerService,
    protected router: Router
  ) { }

  /**
   * Sets the initiallyValid of the checkin requirements
   * Sets the passengers and passengerLength values
   * Subscribes to the activePassengerIndex behavior subject and updates the activePassengerIndex on change
   */
  ngOnInit(): void {
    this.checkinRequirements$
      .pipe(
        filter(checkinRequirements => !!checkinRequirements),
        take(1)
      )
      .subscribe(checkinRequirements => {
        this.initiallyValid =
          checkinRequirements.isValid &&
          !checkinRequirements.governmentProgramRequirements['DOCCHECK'];
      });

    // this will return the passengers from the booking
    this.tripDataService.passengers$
      .pipe(
        filter(passengers => !!passengers),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(passengers => {
        this.passengers = passengers;
        this.passengersLength = passengers.length;
      });

    this._activePassengerIndex
      .pipe(takeUntil(this.unsubscribe$), startWith(0))
      .subscribe(index => {
        this.activePassengerIndex = index;
        this.activePassenger.length = 0;

        this.activePassenger.push(this.passengers[index]);
        this.changeDetectorRef.detectChanges();
      });

    this.checkedInPassengerKeys = this.getCheckedInPassengerKeys();
    this.passengerKeysSeatedInRestrictedRows = this.getPassengersSeatedInRestrictedRows();
    this.boardedPassengerKeys = this.getBoardedPassengerKeys();

  }

  /**
   * Components on destory
   * Used to unsubscribe from all subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Add the Passenger to checkin selection */
  addPassengerToCheckinSelection(passengerKey: string, removeSelected?: boolean) {
    const passengerToCheck: CheckinSelection = {
      journeyKey: this.journey?.journeyKey,
      passengerKey: passengerKey
    };

    // This checks if the passenger is included already in the selection.
    // If so, then it will remove it from the selection (uncheck behavior). Else,
    // It will add it from the selection (check).
    const isPassengerSelected = some(getObservableValueSync(this.selectCheckinSelections$), passengerToCheck);
    if (isPassengerSelected && removeSelected) {
      this.checkinDataService.removeCheckinSelections([passengerToCheck]);
    } else if (!isPassengerSelected) {
      this.checkinDataService.addCheckinSelections([passengerToCheck]);
    }
  }


  async reprintBoardingPass(passengerKey: string){
    const checkedinPassengersBoardingPasses = await this.checkinDataService.generateBoardingPasses(getObservableValueSync(this.bookingJourneys$));
      
    if (checkedinPassengersBoardingPasses) {
      this.store.dispatch(ClearBoardingPasses());
      this.store.dispatch(
        SetBoardingPasses({ boardingPasses: checkedinPassengersBoardingPasses })
      );
    }
   
    this.pageBusyService.hideLoadingSpinner();
    this.store.dispatch(CompleteCheckinAction());
    this.router.navigate([this.flowManagerService.nextUrl()]);
  }
  

  /** This adds all the passenger in the selection */
  addAllPassengerToCheckinSelection() {
    this.passengers.forEach(passenger => {
      this.addPassengerToCheckinSelection(passenger.passengerKey, false)
    })
  }

  /** This removes all the passenger in the selection */
  removeAllPassengerToCheckinSelection() {
    const passengerKeys = getObservableValueSync(this.selectPassengersInCheckinSelections$);
    if (passengerKeys.length > 0) {
      passengerKeys.forEach(passengerKey => this.checkinDataService
        .removeCheckinSelections([{
          passengerKey: passengerKey,
          journeyKey: this.journey?.journeyKey
        }]))
    }
  }

  /** This checks if the journey is valid for check-in.
   * If flight is international or is journey not checkinable, 
   * then it wont be avaialable for checkin.
   * 
   * If all passengers are already checked-in, it won't be considered
   * as checkinable hence, the checking of checked in passenger keys. 
   * This indicates that journey is not checkinable due to passengers
   * are already checked in.
   * 
  */
  isJourneyValidForCheckin(): boolean {
    if (this.isInternational) {
      return false;
    }
    if ((this.checkedInPassengerKeys?.length > 0 || this.isJourneyCheckinable) && this.initiallyValid) {
      return true;
    }

    return false;
  }

  /** This gets the passenger keys of the checked in passengers
  */
  getCheckedInPassengerKeys(): string[] {
    return this.journey.segments.flatMap(segment => {
      return Object.values(segment?.passengerSegment)
        .filter(passengerSegment => passengerSegment.liftStatus === LiftStatus.CheckedIn)
        .map(passengerSegment => passengerSegment?.passengerKey);
    });
  }

  /** This gets the passenger keys of the boarding passengers
  */
  getBoardedPassengerKeys(): string[] {
    return this.journey.segments.flatMap(segment => {
      return Object.values(segment?.passengerSegment)
        .filter(passengerSegment => passengerSegment.liftStatus === LiftStatus.Boarded)
        .map(passengerSegment => passengerSegment?.passengerKey);
    });
  }

  /** This gets the passenger keys of the passengers that are seated
   * on restricted rows based on the config
  */
  getPassengersSeatedInRestrictedRows(): string[] {
    return this.journey.segments.flatMap(segment => {
      return segment.legs.flatMap( leg => {

        return Object.values(segment?.passengerSegment)
        .flatMap(passengerSegment => {

          // const restrictedseatrows = 
          //   leg.legInfo.equipmentType == '320' ?
          //   getObservableValueSync(this.selectRestrictedSeatRowsConfig) : 
          //   getObservableValueSync(this.selectRestrictedSeatRowsATRConfig);
          
          const restrictedseatrows = this.selectRestrictedSeatRowsConfig[leg.legInfo.equipmentType];

          const { seats } = passengerSegment || {};
          if (!seats) return [];
            
          return Object.values(seats)
            .filter(seat => restrictedseatrows.some(seatRow => seatRow == seat.unitDesignator.slice(0, -1)))
            .map(seat => passengerSegment.passengerKey);
        });
      });
    });
  }

  

  /**
   * Submits the active passengers updated information
   * Increased the activePassengerIndex by 1 if possible
   * Emits checkinRequirementsComplete when activePassenger is the last passenger and checkin requirements are now valid
   */
  async next(): Promise<void> {
    if (this.checkinComplete || this.initiallyValid) {
      this.checkinRequirementsComplete.emit();
      return;
    }
    this.pageBusyService.showLoadingSpinner();
    if (!this.initiallyValid) {
      const validPassenger = await this.currentPassengerForm.submitPassenger();
      if (!validPassenger) {
        this.pageBusyService.hideLoadingSpinner();
        return;
      }
    }
    if (this.activePassengerIndex + 1 === this.passengersLength) {
      const checkinRequirements = await asPromise(this.checkinRequirements$);
      let valid: boolean;

      if (this.allowTravelDocuments) {
        const passengerKeys = this.passengers.map(p => p.passengerKey);
        valid =
          await this.checkinRequirementsService.verifyDocumentsAndCheckinRequirements(
            checkinRequirements,
            passengerKeys
          );
      } else {
        valid = checkinRequirements.isValid;
      }
      if (valid) {
        this.checkinComplete = true;
        this.checkinRequirementsComplete.emit();
      } else {
        this.pageBusyService.hideLoadingSpinner();
        this.showTravelDocError();
      }
    } else {
      this._activePassengerIndex.next(this.activePassengerIndex + 1);
    }
    this.pageBusyService.hideLoadingSpinner();
  }

  /**
   * Sets activePassengerIndex to the previous passenger if possible
   */
  previous(): void {
    if (this.activePassengerIndex !== 0) {
      this._activePassengerIndex.next(this.activePassengerIndex - 1);
    }
  }

  /**
   * Show travel doc error and emit start over
   */
  showTravelDocError(): void {
    this.travelDocErrorModal.show();
    asPromise(this.travelDocErrorModal.onConfirmClick).then(() =>
      this.startOver.emit()
    );
  }
}
