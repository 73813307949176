import {
    JourneySsrAvailability,
    LegSsrAvailability,
    SegmentSsrAvailability
  } from '@navitaire-digital/web-data-4.5.0';
  import { getSsrSellKey } from './get-ssr-sell-key';
  
  /**
   * Get the sell keys for the given ssr codes and passenger key from the provided availability object
   */
  export function getSellkeysFromSelectedJourney(
    ssrCodes: string[],
    passengerKey: string,
    sellAvailabilities:
      | LegSsrAvailability[]
      | SegmentSsrAvailability[]
      | JourneySsrAvailability[],
    segmentKey: string[],
    journeyIndex: number
  ): string[] {
    const sellKeys: string[] = [];
    sellAvailabilities.forEach(
      (
        availability:
          | LegSsrAvailability
          | SegmentSsrAvailability
          | JourneySsrAvailability
      ) => { 
        for(var i in  ssrCodes){
            var ssrCode = ssrCodes[i];
            // if(ssrCode == "SSD" && journeyIndex > 0) continue;
            sellKeys.push(getSsrSellKey(ssrCode, passengerKey, availability));
        }
      }
    );
    console.log(segmentKey);
  
    return sellKeys;
  }
  
  export interface availabilityv2 {
    journeyKey: string;
}