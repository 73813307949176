import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewEncapsulation
  } from '@angular/core';
  import { Advertisement } from 'projects/extensions/src/lib/services/citilink-api';
  
  @Component({
    selector: 'boarding-pass-advertising',
    templateUrl: './boarding-pass-advertising.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['boarding-pass-advertising.scss']
  })
  export class BoardingPassAdvertisingComponent implements OnInit {
    @Input() advertisement: Advertisement;
    @Input() imageUrl: string;
    @Input() imageLink: string;
    
    constructor() {}
  
      ngOnInit(): void {}

      clickButton(): void{
        window.open(this.imageLink, '_blank');
      }
  
  }
  