import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  PassengerFee,
  SsrByKeysRequestv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  NskLocalizationSelectors,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { mergeMap, Subject, takeUntil } from 'rxjs';
import { PageBusyService } from '../../../common/page-busy.service';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import { InsuranceData, QGSsrDataService, QGFeesDataService } from '@customer/extensions';
import { InsuranceSsrPriceConfiguration, selectInsuranceSsrPriceConfig } from '../../../config';
import { SetInsuranceAPIResponse } from '../../../store';

@Component({
  selector: 'navitaire-digital-select-insurance-page',
  templateUrl: './select-insurance-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./select-insurance.scss']
})
export class SelectInsurancePageComponent implements OnInit, OnDestroy {
  protected get isInternational(): boolean {
    const journeys = getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingJourneys)
    );

    return journeys && journeys[0]?.segments?.some(s => s.international);
  }

  protected get bookingFees(): PassengerFee[] {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectFeesAsArray)
    );
  }

  protected get journeyCount(): number {
    const journeys = getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingJourneys)
    );

    return journeys.length??1;
  }

  insPriceConfig: InsuranceSsrPriceConfiguration[];

  unsubscribe$ = new Subject<void>();
  analyticsUnsubscribe$ = new Subject<void>();

  isManageFlow: boolean = getObservableValueSync(
    this.store.select(BookingSelectors.selectIsBookingCommitted)
  );

  // insuranceAPIData: InsuranceData[] = getObservableValueSync(
  //   this.store.select(selectInsuranceAPIResponse)
  // );

  insuranceAPIData: InsuranceData[] = undefined;

  constructor(
    protected extrasManagerStore: ExtrasManagerStore,
    protected location: Location,
    protected ssrDataService: QGSsrDataService,
    protected store: Store,
    protected router: Router,
    protected sessionDataService: SessionDataService,
    protected http: HttpClient,
    protected feesDataService: QGFeesDataService,
    protected pageBusyService: PageBusyService
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.insuranceAPIData || !this.insuranceAPIData.length) {
      this.pageBusyService.showLoadingSpinner();

      this.store.select(selectInsuranceSsrPriceConfig)
      .pipe(
        takeUntil(this.unsubscribe$),
        mergeMap(insPriceConfig => {
          this.insPriceConfig = insPriceConfig;
          return this.ssrDataService.getInsurancePrices(this.isInternational);
        })
      )
      .subscribe((insuranceAPIData : InsuranceData[]) => {
        this.store.dispatch(
          SetInsuranceAPIResponse({
            insuranceDataResponse: insuranceAPIData
          })
        );
        this.insuranceAPIData = insuranceAPIData;
        this.pageBusyService.hideLoadingSpinner();
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  moveToNextJourney(): void {
    this.extrasManagerStore.moveToNextJourney();
    this.extrasManagerStore.moveToFirstPassenger();
  }

  async sellInsurance(): Promise<void> {
    await this.sellInsuranceSelection();
    this.location.back();
  }

  async sellInsuranceSelection(): Promise<void> {
    const insuranceChanges = getObservableValueSync(
      this.extrasManagerStore.selectInsuranceChanges$
    );

    this.pageBusyService.showLoadingSpinner();
    if (insuranceChanges.delete.length > 0) {
      await this.ssrDataService.removeSsrs(insuranceChanges.delete);
    }

    if (insuranceChanges.sell.length > 0) {
      const currencyCode = getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
        )
      );

      const request: SsrByKeysRequestv2 = {
        currencyCode: currencyCode,
        keys: insuranceChanges.sell.map(k => ({
          count: 1,
          ssrKey: k
        }))
      };
      await this.ssrDataService.sellSSrs(request);
      // await this.overrideInsurancePrice();
      await this.feesDataService.overrideInsurancePrice();
    }
    this.pageBusyService.hideLoadingSpinner();
  }

  // async overrideInsurancePrice(): Promise<void> {
  //   if (
  //     !this.bookingFees ||
  //     !this.bookingFees.length ||
  //     !this.insuranceAPIData?.length
  //   ) {
  //     return;
  //   }

  //   const insuranceCodes = this.insuranceAPIData.map(data => data.SSRCode);
    
  //   const insuranceFees = this.bookingFees.filter(fee =>
  //     insuranceCodes?.includes(fee.code)
  //   );

  //   for (const fee of insuranceFees) {
  //     const insPriceType = this.insPriceConfig.find(data => data.ssrCode === fee.code).type;
  //     const feeOverrideRequest: FeeRequestBase = {
  //       amount: this.insuranceAPIData.find(s => fee.code === s.SSRCode).Price / (insPriceType === InsuranceSsrPriceType.JOURNEY ? this.journeyCount : 1)
  //     };

  //     await this.feesDataService.overrideFee(
  //       fee.passengerFeeKey,
  //       feeOverrideRequest
  //     );
  //   }
  // }
}
