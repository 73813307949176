import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  BookingStatus,
  CheckinRequirements,
  Journey
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  CheckinDataService,
  NskLocalizationSelectors,
  ProfileDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject, filter, map, take, takeUntil } from 'rxjs';
import { RedirectService } from '../../../common/url-redirect.service';
import { NskCheckinSelectors } from '../../../store/check-in/checkin.selectors';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { AppBookingFlowActions, BookingTransactionType } from '../../../analytics';

@Component({
  selector: 'navitaire-digital-itinerary-page',
  templateUrl: './itinerary-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['itinerary-page.scss']
})
export class ItineraryPageComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  amountDue: number = 0;
  transactionResult: string;
  isInternational: boolean = false;
  availableForCheckin: boolean = false;

  isUserLoggedIn$ = this.profileDataService.loggedIn$;
  checkinRequirements$: Observable<CheckinRequirements> =
    this.checkinDataService.checkinRequirements$;

  bookingJourneys$: Observable<Journey[]> = this.store.select(
    NskCheckinSelectors.selectAllJourneys
  );
  departingJourney$: Observable<Journey> = this.bookingJourneys$.pipe(
    map(journeys => (journeys && journeys?.length ? journeys[0] : undefined))
  );
  returningJourney$: Observable<Journey> = this.bookingJourneys$.pipe(
    map(journeys =>
      journeys && journeys?.length ? journeys[1] ?? undefined : undefined
    )
  );
  

  constructor(
    protected bookingDataService: BookingDataService,
    protected profileDataService: ProfileDataService,
    protected checkinDataService: CheckinDataService,
    protected store: Store,
    protected router: Router,
    protected redirectService: RedirectService
  ) {
    // this.store.dispatch(
    //   PageAction({
    //     event: PageEventType.BookingItinerary
    //   })
    // );
  }

  protected get getActiveLocale(): string {
    return getObservableValueSync(
      this.store.select(
        NskLocalizationSelectors.selectActiveLocaleOrDefaultCode
      )
    );
  }

  ngOnInit(): void {
    this.bookingDataService.booking$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(booking => {
        this.amountDue = booking?.breakdown?.balanceDue;

        if (booking?.info?.status === BookingStatus.Hold) {
          this.transactionResult = 'HOLD';
        } else {
          this.transactionResult = 'CONFIRMED';
        }

        this.store.dispatch(
          AppBookingFlowActions.purchase({transactionType: BookingTransactionType.InitialPurchase, 
             recordLocator:booking?.recordLocator}) 
        )
      });

    this.isInternational = getObservableValueSync(
      this.departingJourney$
    ).segments.some(segment => segment?.international);

    this.checkinRequirements$
      .pipe(
        filter(checkinRequirements => !!checkinRequirements),
        take(1)
      )
      .subscribe(checkinRequirements => {
        this.availableForCheckin =
          checkinRequirements.isValid && !this.isInternational;
      });
  }

  /**
   * Store the booking PNR and Last Name before redirecting. This will indicate the booking that will be
   * retrieved to be able to redirect to MB results page.
   */
  async navigateToManageResultsPage(): Promise<void> {
    // const booking = getObservableValueSync(this.bookingDataService.booking$)
    // const pnrAndLastNameRequest: BookingRetrieveByLastNameRequest = {
    //   lastName: booking?.contacts['P']?.name?.last,
    //   recordLocator: booking?.recordLocator
    // };
    // this.store.dispatch(
    //   SetPnrAndLastNameToRetrieveForManageFlow({pnrAndLastNameToRetrieveForManageFlow: pnrAndLastNameRequest}))
    // await this.router.navigate(['/mytrips/search']);

    // temporary redirect link for live at 28 JUNE
    this.redirectService.redirect('https://book.citilink.co.id/RetrieveBooking.aspx?culture='+this.getActiveLocale);
  }

  async navigateToRefundSite(): Promise<void> {
    await this.redirectService.redirect('https://www.citilink.co.id/refund');
  }

  async navigateToCheckinHome(): Promise<void> {
    await this.router.navigate(['/mytrips/search']);

    // temporary redirect link for live at 28 JUNE
    //this.redirectService.redirect('https://book.citilink.co.id/SearchWebCheckin.aspx?culture='+this.getActiveLocale);
  }
}
