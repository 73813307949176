import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  BookingPriceBreakdown,
  CommitPassengerFeeRequest,
  CommitRequestv2,
  Payment,
  TripTypeSelection
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  NskAvailabilitySelectors,
  NskLocalizationSelectors,
  PaymentDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { AppBookingFlowActions } from '../../analytics/actions/booking-flow/app-booking-flow.actions';
import { EcommerceParams } from '../../../../../app/src/app/analytics/google/models/pos-event/ecommerce.model';
import { selectSelectedPaymentMethod } from '../../store/selectors';
import { PaymentService } from '../payment.service';
import { QGFeesDataService } from '@customer/extensions';
import { PageBusyService } from '../../common/page-busy.service';
import { RedirectService } from '../../common';
import { REDIRECT_EXTERNAL_PAYMENT_CODE, REDIRECT_EXTERNAL_PAYMENT_URL } from '../../config/injection.tokens';
import { PaymentMethodDetail } from '../../config/cdk-configuration.model';
import { ActualBookingSummarySelectors } from '../../booking-summary/selectors/booking-summary-selectors-after-trip-sell';
import { FlowManagerService } from '../../app-state/flow-manager.service';
import { ManageBookingService } from '../../manage/manage-booking.service';
import { BookingSummaryFlowType } from '../../booking-summary/enumerations/booking-summary-flow-type';

@Component({
  selector: 'navitaire-digital-payment-method-page',
  templateUrl: './payment-method-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./payment-method-page.scss']
})
export class PaymentMethodPageComponent implements OnInit, OnDestroy {
  displayBookingSummaryServiceFee: BehaviorSubject<number> =
    new BehaviorSubject(0);

  public breakdown: BookingPriceBreakdown | undefined;

  paymentMethodDetails: PaymentMethodDetail;
  category: string;

  paymentName: string;
  iconClass?: string;
  paymentLink?: string;
  serviceFeeCode?: string;
  bookingFlow: string = '';
  displayPriceDetails : boolean = false;
  displayFlightDetails : boolean = false;
  displayPassengerDetails : boolean = false;

  unsubscribe$ = new Subject<void>();
  tripType$: Observable<TripTypeSelection> = this.store.select(
    NskAvailabilitySelectors.selectTripTypeFromAvailabilityRequest
  );
  origin$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestOrigin
  );
  destination$: Observable<string> = this.store.select(
    NskAvailabilitySelectors.selectAvailabilityRequestDestination
  );

  total$: Observable<number> = this.store.select(
    ActualBookingSummarySelectors.selectTotalCost
  );

  currencyCode$: Observable<string> = this.store.select(
    NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
  );

  isManageFlow: boolean = this.flowManagerService.getFlowName() === 'manage';

  successRoute: string = this.isManageFlow
    ? 'manage/itinerary'
    : 'booking/itinerary';
  serviceFee: number = 0;
  totalDisplayPrice: number = getObservableValueSync(this.total$);

  selectedJourneyOrigin: string = '';
  selectedJourneyDestination: string = '';

  @Output() paymentSuccess: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected store: Store,
    protected tripDataService: TripDataService,
    protected paymentDataService: PaymentDataService,
    protected paymentService: PaymentService,
    protected bookingDataService: BookingDataService,
    protected router: Router,
    protected ngZone: NgZone,
    protected httpClient: HttpClient,
    protected feesDataService: QGFeesDataService,
    protected pageBusyService: PageBusyService,
    protected redirectService: RedirectService,
    protected flowManagerService: FlowManagerService,
    @Inject(REDIRECT_EXTERNAL_PAYMENT_URL)
    protected externalPaymentUrl: string,
    @Inject(REDIRECT_EXTERNAL_PAYMENT_CODE)
    protected commCode: string,
    protected manageBookingService: ManageBookingService
  ) {}

  ngOnInit(): void {
    const paymentConfigDetails = getObservableValueSync(
      this.store.select(selectSelectedPaymentMethod)
    );

    if (paymentConfigDetails) {
      this.paymentMethodDetails = paymentConfigDetails.detail;
      this.category = paymentConfigDetails.category;
      this.paymentName = this.paymentMethodDetails?.displayName;
      this.iconClass = this.paymentMethodDetails?.iconClass;
      this.serviceFeeCode = this.paymentMethodDetails?.feeCode;
      this.paymentLink = this.externalPaymentUrl;
    }

    this.tripDataService.breakdown$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.breakdown = value;
      });

    this.manageBookingService.managedJourney$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedJourney => {
        this.selectedJourneyDestination =
          selectedJourney?.designator?.destination;
        this.selectedJourneyOrigin = selectedJourney?.designator?.origin;
      });

    this.setServiceFeePrice();
    this.displayBookingSummaryItems();
    
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async submitPayment(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();

    if (this.paymentMethodDetails.useExternalPaymentLink) {
      await this.processRedirectionLinkPayment();
    } else {
      await this.processAutoHoldPaymentType();
    }

    this.pageBusyService.hideLoadingSpinner();
  }

  async processRedirectionLinkPayment(): Promise<void> {
    if (this.breakdown?.balanceDue > 0) {
      try {
        if (
          !this.bookingDataService.booking?.recordLocator ||
          this.isManageFlow
        ) {
          await this.holdBooking();
        }

        if (this.serviceFeeCode) {
          await this.addServiceFee();
        }

        const recordLocator = this.bookingDataService.booking.recordLocator;
        if (recordLocator) {
          const redirectionLink = this.formatRedirectionLink(recordLocator);

          // In this case, we won't be able to catch if the payment gateway threw an error.

          let firefoxAgent = this.getBrowserName() === 'Firefox';

          this.redirectService.navigate(
            redirectionLink,
            firefoxAgent ? '_blank' : '_self'
          );
        }
      } catch (e) {
        await this.processAutoHoldPaymentType();
      }
    }
  }

  async processAutoHoldPaymentType(): Promise<void> {
    if (this.breakdown?.balanceDue > 0) {
      if (this.serviceFeeCode) {
        await this.addServiceFee();
      }

      await this.holdBooking();
      this.navigateToSuccess();
    }
  }

  formatRedirectionLink(recordLocator: string): string {
    // const commCode = getObservableValueSync(
    //   this.store.select(selectPaymentMethodConfig)
    // )?.espayCommCode;
    const params: { [key: string]: string }[] = [
      {
        key: 'url',
        value: `${window.location.origin}/${this.successRoute}`
      },
      { key: 'PMC', value: this.paymentMethodDetails.paymentCode },
      { key: 'bankCode', value: this.paymentMethodDetails.bankCode },
      {
        key: 'productCode',
        value: this.paymentMethodDetails.productCode
      },
      { key: 'paymentId', value: recordLocator },
      { key: 'commCode', value: this.commCode }
    ];

    const param: string[] = [];
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        param.push(params[key].key + '=' + params[key].value);
      }
    }

    return param?.length ? this.externalPaymentUrl + '?' + param.join('&') : '';
  }

  /** Track Added payments */
  trackAddPaymentEvent(): void {
    // const recentPayment = this.getRecentPayment();

    // if (!recentPayment) {
    //   return;
    // }

    const ecommerce: EcommerceParams = {
      currency: this.tripDataService.currencyCode,
      value: this.breakdown?.totalCharged,
      payment_type: this.category,
      items: []
    };

    
    this.store.dispatch(AppBookingFlowActions.addpayment(ecommerce));
  }

  /** Returns the most recent Payment */
  getRecentPayment(): Payment {
    const payments = this.paymentDataService.payments;

    if (!payments || !payments.length) {
      return null;
    }

    return payments[payments.length - 1];
  }

  async holdBooking(): Promise<void> {
    const commitRequest: CommitRequestv2 = {
      hold: null,
      notifyContacts: false,
      restrictionOverride: false
    };

    try {
      this.trackAddPaymentEvent();
      await this.bookingDataService.commitBooking(commitRequest);
    } catch (err) {
      console.log(err);
    }
  }

  navigateToSuccess(): void {
    this.ngZone.run(() => {
      this.router.navigate([this.successRoute]);
    });
  }

  async addServiceFee(): Promise<void> {
    const request: CommitPassengerFeeRequest = {
      feeCode: this.serviceFeeCode
    };

    await this.feesDataService.addFee(request);
  }

  async setServiceFeePrice(): Promise<void> {
    if (!this.serviceFeeCode) {
      return;
    }

    const feeDetail = await this.feesDataService.getFeePrice(
      this.serviceFeeCode
    );

    if (feeDetail && feeDetail[0]) {
      const fee = feeDetail[0];
      this.serviceFee = fee.foreignAmount;
      this.displayBookingSummaryServiceFee.next(this.serviceFee);
      this.totalDisplayPrice += fee.amount;
    }
  }

  getBrowserName(): string {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE';
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bEdg\/(\d+)/);
      if (tem != null) {
        return 'Edge(Chromium)';
      }
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        return 'Opera';
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }

    return M[0];
  }

  displayBookingSummaryItems(): void{
    if(this.isManageFlow){
      this.bookingFlow = BookingSummaryFlowType.MANAGE;
      this.displayPriceDetails = true;
      this.displayFlightDetails = false;
      this.displayPassengerDetails = false;
    } else {
      this.bookingFlow = BookingSummaryFlowType.ACTUAL;
      this.displayPriceDetails = true;
      this.displayFlightDetails = true;
      this.displayPassengerDetails = true;
    }
  }
}
