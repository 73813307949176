import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { methodNotImplemented } from '@navitaire-digital/clients-core';
import {
  asPromise,
  BookingQueueInfo,
  CheckinPassengerRestrictionType,
  CheckinRestrictionType,
  FlightOperationalAttribute
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  CheckinDataService,
  MULTIPLE_CARRIERS,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { WindowOpeningService } from '../../common/window-opening.service';

enum OptionsComponentDisplayOption {
  NONE = 'NONE',
  EXTERNAL_CARRIER_CHECKIN = 'EXTERNAL_CARRIER_CHECKIN',
  CONTACT_AGENT = 'CONTACT_AGENT',
  CHECKIN_AVAILABLE = 'CHECKIN_AVAILABLE',
  CHECKIN_UNAVAILABLE = 'CHECKIN_UNAVAILABLE',
  CHECKIN_CLOSED = 'CHECKIN_CLOSED',
  BOARDING_PASS = 'BOARDING_PASS',
  SELF_SERVE_DELAY = 'SELF_SERVE_DELAY',
  UPCOMING_CHECKIN = 'UPCOMING_CHECKIN',
  CANCELLED = 'CANCELLED',
  IN_FLIGHT = 'IN_FLIGHT',
  SELF_SERVE_TIME_CHANGE = 'SELF_SERVE_TIME_CHANGE',
  SELF_SERVE_SCHEDULE_CHANGE = 'SELF_SERVE_SCHEDULE_CHANGE',
  SELF_SERVE_IROP = 'SELF_SERVE_IROP',
  SUSPENDED = 'SUSPENDED',
  FLIGHT_CLOSED = 'FLIGHT_CLOSED'
}

@Component({
  template: ''
})
export class BaseOptionsComponent {
  displayOption$: Observable<OptionsComponentDisplayOption>;

  displayOptionEnum: typeof OptionsComponentDisplayOption =
    OptionsComponentDisplayOption;

  @Output()
  checkinRequested: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  changeFlightRequested: EventEmitter<void> = new EventEmitter<void>();

  keepDelayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  externalLocator$ = this.store
    .select(BookingSelectors.selectBookingRecordLocators)
    .pipe(
      map(locators => {
        return locators.find(
          locator => locator.interactionPurpose === this.MULTIPLE_CARRIERS_CONST
        );
      })
    );

  constructor(
    protected store: Store,
    @Inject(MULTIPLE_CARRIERS) protected MULTIPLE_CARRIERS_CONST: string,
    protected windowOpeningService: WindowOpeningService,
    protected checkinDataService: CheckinDataService,
    protected tripDataService: TripDataService
  ) {}

  keepDelayedFlight(): void {
    this.keepDelayed$.next(true);
  }

  changeDelayedFlight(): void {
    this.changeFlightRequested.emit();
  }

  async getBoardingPass(): Promise<void> {
    methodNotImplemented('getBoardingPass');
  }

  async checkIn(): Promise<void> {
    methodNotImplemented('getBoardingPass');
  }

  /**
   * Use the operational details to determine the display option
   */
  calculateDisplayOption(
    operationAttributes: FlightOperationalAttribute[],
    queues: BookingQueueInfo[]
  ): OptionsComponentDisplayOption {
    const selfServeMoveDelay = operationAttributes.includes(
      FlightOperationalAttribute.SelfServeMoveDelay
    );
    const boardingPassAvailable = operationAttributes.includes(
      FlightOperationalAttribute.BoardingPassAvailable
    );
    const checkinAvailable = operationAttributes.includes(
      FlightOperationalAttribute.CheckinAvailable
    );

    const checkinClosed = operationAttributes.includes(
      FlightOperationalAttribute.CheckinClosed
    );
    const noOnlineCheckin = operationAttributes.includes(
      FlightOperationalAttribute.NoOnlineCheckin
    );
    const noCarrierCheckin = operationAttributes.includes(
      FlightOperationalAttribute.NoCarrierCheckin
    );
    const landed = operationAttributes.includes(
      FlightOperationalAttribute.Landed
    );

    const flightClosed = operationAttributes.includes(FlightOperationalAttribute.LEG_STATUS_Closed) ||
    operationAttributes.includes(FlightOperationalAttribute.LEG_STATUS_ClosedPending);

    const cancelled =
      operationAttributes.includes(FlightOperationalAttribute.Cancelled) ||
      operationAttributes.includes(
        FlightOperationalAttribute.LEG_STATUS_Canceled
      ) ||
      operationAttributes.includes(
        FlightOperationalAttribute.DEPARTURE_STATUS_Cancelled
      );

    const suspended = operationAttributes.includes(
      FlightOperationalAttribute.LEG_STATUS_Suspended
    );

    const inFlight = operationAttributes.includes(
      FlightOperationalAttribute.InFlight
    );

    const selfServeIROP =
      queues?.filter(q => q.code === 'SELFIR').length > 0 &&
      !checkinClosed &&
      !inFlight;

    const selfServeScheduleChange =
      queues?.filter(q => q.code === 'SELFSC').length > 0 &&
      !checkinClosed &&
      !inFlight;

    const selfServeTimeChange =
      queues?.filter(q => q.code === 'SELFTC').length > 0 &&
      !checkinClosed &&
      !inFlight;

    if (cancelled) {
      return OptionsComponentDisplayOption.CANCELLED;
    } else if (suspended) {
      return OptionsComponentDisplayOption.SUSPENDED;
    } else if (this.keepDelayed$.value === false && selfServeIROP) {
      return OptionsComponentDisplayOption.SELF_SERVE_IROP;
    } else if (this.keepDelayed$.value === false && selfServeMoveDelay) {
      return OptionsComponentDisplayOption.SELF_SERVE_DELAY;
    } else if (this.keepDelayed$.value === false && selfServeScheduleChange) {
      return OptionsComponentDisplayOption.SELF_SERVE_SCHEDULE_CHANGE;
    } else if (this.keepDelayed$.value === false && selfServeTimeChange) {
      return OptionsComponentDisplayOption.SELF_SERVE_TIME_CHANGE;
    } else if (boardingPassAvailable && !landed) {
      return OptionsComponentDisplayOption.BOARDING_PASS;
    } else if (checkinClosed) {
      return OptionsComponentDisplayOption.CHECKIN_CLOSED;
    } else if (noCarrierCheckin) {
      return OptionsComponentDisplayOption.EXTERNAL_CARRIER_CHECKIN;
    } else if (noOnlineCheckin) {
      return OptionsComponentDisplayOption.CONTACT_AGENT;
    } else if (checkinAvailable) {
      return OptionsComponentDisplayOption.CHECKIN_AVAILABLE;
    } else if (inFlight) {
      return OptionsComponentDisplayOption.IN_FLIGHT;
    } else if (!landed) {
      return OptionsComponentDisplayOption.UPCOMING_CHECKIN;
    } else if(flightClosed){
      return OptionsComponentDisplayOption.FLIGHT_CLOSED;
    }
  }

  openWindowWithBoardingPasses(): void {
    this.windowOpeningService.openWindow('boardingpass');
  }

  async selectAllPassengersForCheckin(journeyKey: string): Promise<void> {
    const passengers = await asPromise(this.tripDataService.passengers$);

    return this.checkinDataService.addCheckinSelections(
      passengers.map(p => ({
        journeyKey: journeyKey,
        passengerKey: p.passengerKey
      }))
    );
  }

  updateWithCheckinRestrictions(
    operationalDetails: FlightOperationalAttribute[],
    restrictions: CheckinRestrictionType[],
    passengerRestrictions?: CheckinPassengerRestrictionType[]
  ): FlightOperationalAttribute[] {
    const additionalOperationalDetails: FlightOperationalAttribute[] = [];
    if (
      restrictions.includes(CheckinRestrictionType.RestrictedOnAnotherSystem)
    ) {
      additionalOperationalDetails.push(
        FlightOperationalAttribute.NoCarrierCheckin
      );
    }

    if (restrictions.includes(CheckinRestrictionType.NotAllowed)) {
      additionalOperationalDetails.push(
        FlightOperationalAttribute.NoOnlineCheckin
      );
    }

    if (passengerRestrictions?.length > 0) {
      additionalOperationalDetails.push(
        FlightOperationalAttribute.NoOnlineCheckin
      );
    }

    return [...additionalOperationalDetails, ...operationalDetails];
  }
}
