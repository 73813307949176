import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BookingStatus, CheckinRequirements, Journey } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingDataService, CheckinDataService, NskLocalizationSelectors, ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject, filter, map, take, takeUntil } from 'rxjs';
import { RedirectService } from '../../../common/url-redirect.service';
import { SetManageJourneys } from '../../../store/actions';
import { NskCheckinSelectors } from '../../../store/check-in/checkin.selectors';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { BookingSummaryFlowType } from '../../../booking-summary';
import { SnapshotSelectors } from '../../../snapshot/store/selectors';

@Component({
  selector: 'navitaire-digital-payment-success-page',
  templateUrl: './payment-success-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['payment-success-page.scss']
})
export class PaymentSuccessPageComponent implements OnInit, AfterViewInit {

  unsubscribe$ = new Subject<void>();
  amountDue: number = 0;
  transactionResult: string;
  isUserLoggedIn$ = this.profileDataService.loggedIn$;

  isInternational: boolean = false;
  availableForCheckin: boolean = false;
  checkinRequirements$: Observable<CheckinRequirements> =
    this.checkinDataService.checkinRequirements$;

  bookingJourneys$: Observable<Journey[]> = this.store.select(
    NskCheckinSelectors.selectAllJourneys
  );
  departingJourney$: Observable<Journey> = this.bookingJourneys$.pipe(
    map(journeys => (journeys && journeys.length ? journeys[0] : undefined))
  );
  returningJourney$: Observable<Journey> = this.bookingJourneys$.pipe(
    map(journeys =>
      journeys && journeys.length ? journeys[1] ?? undefined : undefined
    )
  );

  displayType: BookingSummaryFlowType = BookingSummaryFlowType.MANAGE;

  protected get getActiveLocale(): string {
    return getObservableValueSync(
      this.store.select(
        NskLocalizationSelectors.selectActiveLocaleOrDefaultCode
      )
    );
  }

  constructor(
    protected store: Store,
    protected profileDataService: ProfileDataService,
    protected bookingDataService: BookingDataService,
    protected checkinDataService: CheckinDataService,
    protected router: Router,
    protected redirectService: RedirectService
  ) {}

  ngOnInit(): void {
    // When booking snapshot is empty, this page is being accessed via View Itinerary
    // Else, page is accessed from MB Add-ons Page
    const bookingSnapshot = getObservableValueSync(this.store.select(SnapshotSelectors.selectBookingSnapshot));
    if (!bookingSnapshot) {
      this.displayType = BookingSummaryFlowType.PURCHASED;
    }

    this.bookingDataService.booking$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(booking => {
      this.amountDue = booking?.breakdown?.balanceDue;

      if(booking?.info?.status === BookingStatus.Hold){
        this.transactionResult = 'HOLD';
      } else{
        this.transactionResult = 'CONFIRMED';
      }
    });

    this.isInternational = getObservableValueSync(
      this.departingJourney$
    ).segments.some(segment => segment?.international);

    this.checkinRequirements$
      .pipe(
        filter(checkinRequirements => !!checkinRequirements),
        take(1)
      )
      .subscribe(checkinRequirements => {
        this.availableForCheckin =
          checkinRequirements.isValid && !this.isInternational;
      });
  }

  ngAfterViewInit(): void {
    this.store.dispatch(SetManageJourneys({ manageJourneys: null }));
  }

  async navigateToManageResultsPage(): Promise<void> {
    //await this.router.navigate(['/mytrips/search']);
    this.redirectService.redirect('https://book.citilink.co.id/RetrieveBooking.aspx?culture='+this.getActiveLocale);
  }

  async navigateToCheckinHome(): Promise<void> {
    // await this.router.navigate([`/home/checkin`]);
    await this.router.navigate(['/mytrips/search']);
  }

  async navigateToRefundSite():  Promise<void> {
    await this.redirectService.redirect('https://www.citilink.co.id/refund');
  }
}
