import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Booking,
  getCarrierBySeatmapReference,
  SeatMapAvailability,
  SsrByKeysRequestv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  NskLocalizationSelectors,
  NskSeatmapSelectors,
  SeatDataService,
  SsrDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { planeEnterLeave } from '../../../common/animations';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { PageBusyService } from '../../../common/page-busy.service';
import { WindowRefService } from '../../../common/window-ref.service';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import { PassengerSeatSelection } from '../../../seatmap/models/passenger-seat-selection.model';
import { SeatmapService } from '../../../seatmap/services/seatmap-service';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { AppBookingFlowActions } from '../../../analytics/actions/booking-flow/app-booking-flow.actions';

@Component({
  selector: 'navitaire-digital-select-seats-page',
  templateUrl: './select-seats-page.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [planeEnterLeave],
  styleUrls: ['seats-page.scss']
})
export class SelectSeatsPageComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  selectedSeatmapContainer: SeatMapAvailability;
  selectedSeatmapKey: string;
  currentBooking: Booking;
  allSeatsSelected: boolean = true;
  isCurrentSeatmapLast$ = this.extrasManagerStore.selectIsCurrentSeatmapLast$;
  @ViewChild('dialog')
  dialog: ElementRef;
  hasOneJourney$: Observable<boolean> =
    this.extrasManagerStore.selectHasOneJourney$;

  analyticsUnsubscribe = new Subject<void>();

  selectedPassenger$: Observable<string> =
    this.extrasManagerStore.selectSelectedPassengerKey$;
  selectedSeatmapKey$: Observable<string> =
    this.extrasManagerStore.selectSeatmapKey$;
  seatSelections$: Observable<PassengerSeatSelection[]> =
    this.extrasManagerStore.selectSeatSelections$;

  constructor(
    protected seatDataService: SeatDataService,
    public seatmapService: SeatmapService,
    protected tripDataService: TripDataService,
    protected location: Location,
    protected route: ActivatedRoute,
    protected extrasManagerStore: ExtrasManagerStore,
    protected breakpoint: BreakpointObserver,
    protected changeDetectorRef: ChangeDetectorRef,
    protected overlayService: NavitaireDigitalOverlayService,

    protected bookingDataService: BookingDataService,
    protected store: Store,
    protected windowRefService: WindowRefService,
    protected pageBusyService: PageBusyService,
    protected ssrDataService: SsrDataService
  ) { }

  ngOnInit(): void {
    this.extrasManagerStore.resetSeatSelection();

    combineLatest([
      this.extrasManagerStore.selectSeatmapKey$,
      this.store.select(NskSeatmapSelectors.selectSeatmaps)
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([seatmapKey, seatmaps]) => seatmaps[seatmapKey]),
        distinctUntilChanged(
          (previous, current) =>
            previous?.seatMap?.seatmapReference ===
            current?.seatMap?.seatmapReference
        )
      )
      .subscribe(seatmap => {
        this.selectedSeatmapContainer = null;
        this.changeDetectorRef.detectChanges();
        this.selectedSeatmapContainer = seatmap;
        this.changeDetectorRef.detectChanges();
      });

    this.extrasManagerStore.selectSelectedLegKey$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(legKey => !!legKey),
        distinctUntilChanged()
      )
      .subscribe(legKey => {
        this?.windowRefService?.window.scrollTo(0, 0);
      });

    this.extrasManagerStore.selectSelectedPassengerKey$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(passengerKey => !!passengerKey),
        distinctUntilChanged()
      )
      .subscribe(passengerKey => this.selectPassenger(passengerKey));

    this.extrasManagerStore.selectSeatmapKey$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(seatmapKey => {
        this.selectedSeatmapKey = seatmapKey;
      });

    this.bookingDataService.booking$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(booking => {
        this.currentBooking = booking;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.closeDialog();
  }

  showSeatDetails(): void {
    this.overlayService.showMobile(this.dialog);
  }
  closeDialog(): void {
    this.overlayService.hide();
  }

  selectPassenger(value: string): void {
    this.extrasManagerStore.setPassenger(value);
  }

  selectionMade(): void {
    this.extrasManagerStore.moveToNextPassenger();
  }

  async sellSeatSSRSelection(): Promise<void> {
    const equipmentSSRChanges = getObservableValueSync(
      this.extrasManagerStore.selectEquipmentSSRChanges$
    );

    if (equipmentSSRChanges.delete.length > 0) {
      await this.ssrDataService.removeSsrs(equipmentSSRChanges.delete);
    }
    // Only Sell
    if (equipmentSSRChanges.sell.length > 0) {
      const currencyCode = getObservableValueSync(
        this.store.select(
          NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
        )
      );

      const request: SsrByKeysRequestv2 = {
        currencyCode: currencyCode,
        keys: equipmentSSRChanges.sell.map(k => ({
          count: 1,
          ssrKey: k
        }))
      };
      await this.ssrDataService.sellSSrs(request);
    }

    this.store.dispatch(AppBookingFlowActions.equipmentbasedssrchanges(equipmentSSRChanges));
  }

  /**
   * Method to sell seats
   * Checks for insurance when seat changes exist
   * Sells and/or deletes any seat changes
   * Navigates back to extras main page
   */
  //check this
  async completeSell(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();

    await this.seatmapService.sell();
    this.analyticsUnsubscribe.next();
    this.analyticsUnsubscribe.complete();
    this.location.back();
    this.pageBusyService.hideLoadingSpinner();
  }

  /**
   * Returns boolean for if seat changes have been made
   */
  hasSeatChanges(): boolean {
    const { changedRequests, removedRequests } =
      this.seatmapService.createSeatRequests();
    return changedRequests?.length > 0 || removedRequests?.length > 0;
  }

  /**
   * Handle select next seatmap.
   */
  async moveToNextSeatmap(): Promise<void> {
    this.extrasManagerStore.moveToNextSeatmap();
    this.extrasManagerStore.moveToNextSegment();
    this.extrasManagerStore.moveToFirstPassenger();
  }

  getSegmentCarrierBySeatmapReference(): string {
    const journeys = this.bookingDataService?.booking?.journeys;
    if (journeys) {
      return getCarrierBySeatmapReference(journeys, this.selectedSeatmapKey);
    }
  }
}
