import { Injectable } from '@angular/core';
import { InsurancePurposeFor, InsuranceSsrPriceConfiguration, InsuranceSsrPriceType, selectInsuranceAPIResponse, selectInsuranceSsrPriceConfig } from '@customer/components';
import { InsuranceData } from '@customer/extensions';
import { ApiRestHttpResponse, getObservableValueSync } from '@navitaire-digital/clients-core';
import { FeeRequestBase, IJsonResponseOfIListOfServiceCharge, Journey, PassengerFee, ServiceCharge } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingDataService,
  BookingSelectors,
  CurrencyManipulationService,
  FeesDataService,
  NgBookingClientService,
  NgResourcesClientService,
  NskLocalizationSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class QGFeesDataService extends FeesDataService {

  
  get activeCurrencyCode(): string {
    return getObservableValueSync(
      this.store.select(
        NskLocalizationSelectors.selectActiveCurrencyOrDefaultCode
      )
    );
  }

  get bookFees(): PassengerFee[] {
    return getObservableValueSync(
      this.store.select(BookingSelectors.selectFeesAsArray)
    );
  }

  get insuranceAPIData(): InsuranceData[] {
    return getObservableValueSync(
      this.store.select(selectInsuranceAPIResponse)
    );
  }

  get insPriceConfig(): InsuranceSsrPriceConfiguration[] {
    return getObservableValueSync(
      this.store.select(selectInsuranceSsrPriceConfig)
    );
  }

  get journeyCount(): number {
    let journeys:Journey[] = getObservableValueSync(
      this.store.select(BookingSelectors.selectBookingJourneys)
    );

    return journeys.length??1;
  }

  constructor(
    protected bookingDataService: BookingDataService,
    protected bookingClient: NgBookingClientService,
    protected store: Store,
    protected resourcesClient: NgResourcesClientService,
    protected currencyManipulationService: CurrencyManipulationService
  ) {
    super(
      bookingDataService,
      bookingClient,
      store,
      resourcesClient,
      currencyManipulationService
    );
  }

    /**
   * Gets the price associated with the provided fee code.
   * @param feeCode
   * @returns
   */
     public async getFeePrice(feeCode: string): Promise<ServiceCharge[]> {
        let details: ApiRestHttpResponse<IJsonResponseOfIListOfServiceCharge> = null;
        try {
          details =
            await this.bookingClient.nsk_v1_booking_fee_get(
              null,
              null,
              this.activeCurrencyCode,
              feeCode,
              null
            );
        } catch (e) {
          console.warn(`Unable to fetch booking/fee/${feeCode}:${e}`);
        }

        return details?.body?.data;
      }
    

  /**
   * overide the price associated with the provided insurance fee code.
   * @param
   * @returns
   */
    async overrideInsurancePrice(): Promise<void>{
  
      if (
        !this.bookFees ||
        !this.bookFees.length ||
        !this.insuranceAPIData?.length
      ) {
        return;
      }
      const insuranceCodes = this.insuranceAPIData.map(data => data.SSRCode);
      
      const insuranceFees = this.bookFees.filter(fee =>
        insuranceCodes?.includes(fee.code)
      );
  
      for (const fee of insuranceFees) {
        const insPriceSsrConfig = this.insPriceConfig.find(data => data.ssrCode === fee.code);
        let amount = this.insuranceAPIData.find(s => fee.code === s.SSRCode).Price;
        if(insPriceSsrConfig.purpose === InsurancePurposeFor.BAG){
          // check bag first
          amount = this.insuranceAPIData.find(s => fee.code === s.SSRCode).Price / (insPriceSsrConfig.type === InsuranceSsrPriceType.JOURNEY ? this.journeyCount : 1);
        }
        else amount = this.insuranceAPIData.find(s => fee.code === s.SSRCode).Price / (insPriceSsrConfig.type === InsuranceSsrPriceType.JOURNEY ? this.journeyCount : 1);

        
        
        
        const feeOverrideRequest: FeeRequestBase = {
          amount: amount
        };
  
        await this.overrideFee(
          fee.passengerFeeKey,
          feeOverrideRequest
        );
      }
    }
}
