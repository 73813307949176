<navitaire-digital-header-navigation
  class="header-navigation"
  [showBackButton]="true"
  [showMobileBackButton]="true"
  [pageTitle]="title"
  [showTitle]="true"
  navitaireDigitalCurrentRouteAttribute
  navitaireDigitalScrolledTop
>
</navitaire-digital-header-navigation>

<div class="checkin-details-container">
  <div class="checkin-info-container">
    <div class="flight-details-container container">
      <div class="under-header-container container">
        <div class="trip-card-header">
          <div class="trip-card-title" translate>Departing Flight</div>
          <div class="trip-card-markets">
            {{
              (departingJourney$ | async)?.designator?.origin
                | stationCodeToStationName$ : true
                | async
            }}
            -
            {{
              (departingJourney$ | async)?.designator?.destination
                | stationCodeToStationName$ : true
                | async
            }}
          </div>
        </div>
        <div class="under-header-wrapper">
          <navitaire-digital-flight-details
            class="flight-details"
            [journey]="departingJourney$ | async"
            [isCompactViewEnabled]="true"
            [departureTime]="
              departingJourney$ | async | getUpdatedDepartureTime
            "
            [arrivalTime]="departingJourney$ | async | getUpdatedArrivalTime"
            [moreDetailsOption]="false"
          >
            <!-- <div class="flight-status-wrapper">
              <navitaire-digital-flight-status
                class="flight-status"
                [flightStatus]="flightStatusForDepartingJourney$ | async"
              ></navitaire-digital-flight-status>
            </div> -->
          </navitaire-digital-flight-details>
        </div>
      </div>

      <div class="container passenger-container">
        <navitaire-digital-checkin-passengers
          (startOver)="goCheckinHome()"
          [journey]="departingJourney$ | async"
          [isJourneyCheckinable]="
            (checkinableJourneys$ | async).includes(departingJourney$ | async)
          "
          [isInternational]="isInternational"
        ></navitaire-digital-checkin-passengers>
      </div>
    </div>

    <div
      class="flight-details-container container"
      *ngIf="returningJourney$ | async"
    >
      <div class="under-header-container container">
        <div class="trip-card-header">
          <div class="trip-card-title" translate>Returning Flight</div>
          <div class="trip-card-markets">
            {{
              (returningJourney$ | async)?.designator?.origin
                | stationCodeToStationName$ : true
                | async
            }}
            -
            {{
              (returningJourney$ | async)?.designator?.destination
                | stationCodeToStationName$ : true
                | async
            }}
          </div>
        </div>
        <div class="under-header-wrapper">
          <navitaire-digital-flight-details
            class="flight-details"
            [journey]="returningJourney$ | async"
            [isCompactViewEnabled]="true"
            [departureTime]="
              returningJourney$ | async | getUpdatedDepartureTime
            "
            [arrivalTime]="returningJourney$ | async | getUpdatedArrivalTime"
            [moreDetailsOption]="false"
          >
            <!-- <div class="flight-status-wrapper">
              <navitaire-digital-flight-status
                class="flight-status"
                [flightStatus]="flightStatusForReturningJourney$ | async"
              ></navitaire-digital-flight-status>
            </div> -->
          </navitaire-digital-flight-details>
        </div>
      </div>

      <div class="container passenger-container">
        <navitaire-digital-checkin-passengers
          (startOver)="goCheckinHome()"
          [journey]="returningJourney$ | async"
          [isJourneyCheckinable]="
            (checkinableJourneys$ | async).includes(returningJourney$ | async)
          "
          [isInternational]="isInternational"
        ></navitaire-digital-checkin-passengers>
      </div>
    </div>

    <div>
      <navitaire-digital-reminder-card> </navitaire-digital-reminder-card>
    </div>

    <div
      class="button-footer-row"
      *ngIf="!isInternational && (checkinRequirements$ | async)?.isValid"
    >
      <div>
        <div class="terms-checkbox-row">
          <navitaire-digital-checkbox
            [selected]="tacCheckBoxValue"
            (toggleChange)="tacCheckBoxValue = !tacCheckBoxValue"
          ></navitaire-digital-checkbox>
          <span translate>
            checkin.tnc_confirmation
          </span>
        </div>
      </div>

      <button
        class="navitaire-digital-button primary long-button"
        [disabled]="
          (selectCheckinSelections$ | async)?.length > 0 && tacCheckBoxValue
            ? false
            : true
        "
        (click)="complete()"
        [attr.aria-label]="'Continue' | translate"
        translate
        data-cy="continueButton"
      >
        <div translate>Check-in</div>
      </button>
    </div>
  </div>

  <div class="accordion-container">
    <navitaire-digital-pnr-retrieve-panel-accordion
      [fixedPanel]="false"
      [isHazmatEnabled]="true"
      class="pnr-retrieve-accordion"
    >
    </navitaire-digital-pnr-retrieve-panel-accordion>
  </div>
</div>

<navitaire-digital-cms-modal
  [key]="'modal-checkin-failed-travel-docs'"
  #travelDocErrorModal="cmsModal"
></navitaire-digital-cms-modal>
