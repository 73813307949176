import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  asPromise,
  CheckinRestrictionType,
  getNextJourney,
  isJourneyCheckedIn,
  Journey
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  SessionDataService,
  SettingsDataService,
  TripDataService,
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { BeginCheckinAction } from '../analytics/actions/check-in/begin-checkin-action';
import { CheckinRequirementsService } from '../checkin/services/checkin-requirements.service';
import { WindowOpeningService } from '../common/window-opening.service';
import { MyTripsService } from '../my-trips/my-trips.service';
import { PnrRetrieveComponent } from './pnr-retrieve.component';
import { RetrievalError } from './retrieval-error.enum';
import { NskSettingsSelectors } from '../store/settings/settings.selector';
import { NskCheckinSelectors } from '../store/check-in/checkin.selectors';
import { QGBookingDataService, QGCheckinDataService } from '@customer/extensions';
import { ValidatorsService } from '../forms';

@Component({
  selector: 'navitaire-digital-pnr-retrieve-for-checkin',
  templateUrl: './pnr-retrieve.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['pnr-retrieve.scss']
})
export class PnrRetrieveForCheckinComponent
  extends PnrRetrieveComponent
  implements OnDestroy {
    
  @Input() mobileSearchIcon: string = 'icon_location_solid';
  @Output() retrievingPnr: EventEmitter<null> = new EventEmitter<null>();
  @Output() pnrRetrieved: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected unsubscribe$ = new Subject<void>();

  constructor(
    protected bookingDataService: QGBookingDataService,
    protected sessionDataService: SessionDataService,
    protected tripDataService: TripDataService,
    protected windowOpeningService: WindowOpeningService,
    protected checkinRequirementsService: CheckinRequirementsService,
    protected myTripsService: MyTripsService,
    protected store: Store,
    protected settingsDataService: SettingsDataService,
    protected checkinDataService: QGCheckinDataService,
    protected validatorsService: ValidatorsService,
    protected router: Router
  ) {
    super(
      bookingDataService,
      sessionDataService,
      tripDataService,
      windowOpeningService,
      store,
      settingsDataService,
      checkinDataService,
      validatorsService,
      router
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Retrieves PNR from server and emits events before and after retrieval.
   */
  async retrieve(): Promise<void> {
    this.retrievingPnr.emit();
    this.error = await this.retrievePnrAndGetError();
    this.validError = this.error !== null;


    if (!this.error) {
      this.store.dispatch(BeginCheckinAction());
    }
    this.pnrRetrieved.emit(!this.error);
  }

  /**
   * Retrieves PNR that is ready to be checked-in:
   * - either outbound journey is in checkin window and not restricted
   * - or inbound journey (if it exists) is in checkin window and not restricted

   * After successful checks, it makes the appropriate checkin selection for the journey for all passengers.
   * If there is any error along the way, it is returned as string.
   * @override
   */
  protected async retrievePnrAndGetError(): Promise<RetrievalError> {
    const retrievalError = await super.retrievePnrAndGetError();
    if (retrievalError) {
      if (retrievalError === RetrievalError.NoFutureJourney) {
        return RetrievalError.CheckinAlreadyClosed;
      }
      return retrievalError;
    }

    const nextCheckinableJourneys = getObservableValueSync(
      this.store.select(NskCheckinSelectors.selectNextCheckinableJourneys)
    );

    if (nextCheckinableJourneys?.length > 0) {
      let allowToProceed = false;
      for (const journey of nextCheckinableJourneys) {
        if (await this.isJourneyValidForCheckin(journey)) {
          // await this.selectAllPassengersForCheckin(journey);
          this.myTripsService.selectJourneyToMange(journey, false);
          allowToProceed = true;
        }
      }

      // If one of the journeys is checkinable, then it will proceed
      if (allowToProceed) {
        return;
      }

      // If it contains restrictions, it will check if the restriction is not paid in full
      // Then it will allow to proceed
      const checkInRequirements = getObservableValueSync(this.store.select(NskCheckinSelectors.selectCheckinRequirements))?.restrictions
      if (checkInRequirements?.includes(CheckinRestrictionType.NotPaidInFull)) {
        return;
      }
      this.sessionDataService.clearBookingState();
      return RetrievalError.Restricted;
    }
    else {
      const readonlyjourneys = getObservableValueSync(this.store.select(NskCheckinSelectors.selectReadOnlyJourneys));
      if (readonlyjourneys?.length > 0) {
        return;
      }
    }

    const minutesBeforeDepartureCheckinDisallow = getObservableValueSync(
      this.store.select(
        NskSettingsSelectors.selectMinutesBeforeDepartureCheckinDisallow
      )
    );
    const nextJourney = getNextJourney(
      this.tripDataService.journeys,
      minutesBeforeDepartureCheckinDisallow
    );

    if (nextJourney) {
      return;
    }

    // Has error so remove from state.
    this.sessionDataService.clearBookingState();

    return RetrievalError.CheckinAlreadyClosed;
  }

  /**
   * Checks checkin validity for journey based on NS config.
   * @param journey
   */
  protected async isJourneyValidForCheckin(journey: Journey): Promise<boolean> {
    return await this.checkinRequirementsService.verifyCheckinWithAllowedRestrictions(
      journey.journeyKey
    );
  }

  protected async selectAllPassengersForCheckin(
    journey: Journey
  ): Promise<void> {
    const passengers = await asPromise(this.tripDataService.passengers$);

    return this.checkinDataService.addCheckinSelections(
      passengers.map(p => ({
        journeyKey: journey.journeyKey,
        passengerKey: p.passengerKey
      }))
    );
  }

  protected async isJourneyCheckedIn(journey: Journey): Promise<boolean> {
    return isJourneyCheckedIn(journey);
  }

  protected async getBoardingPasses(journey: Journey): Promise<void> {
    const passengers = this.tripDataService.passengers;
    const passengerKeys = passengers.map(passenger => passenger.passengerKey);
    await this.checkinDataService.fetchBoardingPasses(
      passengerKeys,
      journey.journeyKey
    );
  }
}
