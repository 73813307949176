import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import {
  FlightOperationalAttribute,
  TransportationDesignator
} from '@navitaire-digital/nsk-api-4.5.0';

@Component({
  selector: 'navitaire-digital-my-trip',
  templateUrl: './my-trip.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['my-trip.scss']
})
export class MyTripComponent {
  @Input()
  flightStatus?: FlightOperationalAttribute;
  @Input() designator: TransportationDesignator;
  @Input() overrideDeparture?: string;
  @Input() overrideArrival?: string;
  @Input() stops: number;
  @Input() duration: { hours: number; minutes: number };
  @Input() identifiers: string[];
  @Input() hasMultipleOperators: boolean = false;
  @Input() hasMultipleCarriers: boolean = false;

  FlightStatus: typeof FlightOperationalAttribute = FlightOperationalAttribute;
}
