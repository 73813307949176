import {
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
  Provider
} from '@angular/core';
import {
  AGENCY_HOST_URL,
  AppStateModule,
  BaseAppAnalyticsService,
  cdkConfigurationFeatureKey,
  cdkConfigurationReducer,
  CdkDevToolsModule,
  cdkFlightSearchFeature,
  cdkMobileStoreFeature,
  cdkBookingsQuoteFeature,
  cdkBaggaeAllowanceFeature,
  CmsWebCdkModule,
  GlobalErrorHandler,
  LocalizationModule,
  mobileApplicationStateManagerFeatureKey,
  mobileApplicationStateReducer,
  NavitaireDigitalCommonModule,
  NoopAppAnalyticsService,
  snapshotStoreFeatureKey,
  snapshotStoreReducer,
  storeManagerFeatureKey,
  storeManagerReducer,
  WINDOW
} from '@customer/components';
import { StoreModule } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { cdkConfiguration } from '../cdk-config';

/**
 * Setup initial configuration state
 */
const cdkConfigurationReducerWithInitialState =
  cdkConfigurationReducer(cdkConfiguration);

@NgModule({
  imports: [
    StoreModule.forFeature(cdkMobileStoreFeature),
    StoreModule.forFeature(cdkBookingsQuoteFeature),
    StoreModule.forFeature(cdkBaggaeAllowanceFeature),
    StoreModule.forFeature(cdkFlightSearchFeature),
    StoreModule.forFeature(storeManagerFeatureKey, storeManagerReducer),
    StoreModule.forFeature(
      mobileApplicationStateManagerFeatureKey,
      mobileApplicationStateReducer
    ),
    StoreModule.forFeature(
      cdkConfigurationFeatureKey,
      cdkConfigurationReducerWithInitialState
    ),
    StoreModule.forFeature(snapshotStoreFeatureKey, snapshotStoreReducer),
    CmsWebCdkModule,
    AppStateModule,

    !environment.production ? [CdkDevToolsModule] : [],
    NavitaireDigitalCommonModule
  ],
  declarations: [],
  providers: [],
  exports: [
    NavitaireDigitalCommonModule,
    CmsWebCdkModule,
    LocalizationModule,
    AppStateModule
  ]
})
export class WebCdkModule {
  static commonProviders: Provider[] = [
    GlobalErrorHandler,
    { provide: ErrorHandler, useExisting: GlobalErrorHandler },
    { provide: AGENCY_HOST_URL, useValue: environment.agency?.hostUrl }
  ];

  public static server(): ModuleWithProviders<WebCdkModule> {
    return {
      ngModule: WebCdkModule,
      providers: [
        ...WebCdkModule.commonProviders,
        {
          provide: BaseAppAnalyticsService,
          useClass: NoopAppAnalyticsService
        }
      ]
    };
  }

  public static browser(): ModuleWithProviders<WebCdkModule> {
    return {
      ngModule: WebCdkModule,
      providers: [
        ...WebCdkModule.commonProviders,
        {
          provide: WINDOW,
          // eslint-disable-next-line no-restricted-globals
          useValue: window
        }
      ]
    };
  }
}
