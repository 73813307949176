import { HttpHeaders } from '@angular/common/http';
import { EnvironmentConfiguration } from './environment-model';
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export function defaultHeaders(): HttpHeaders {
  return new HttpHeaders({
    'content-type': 'application/json',
    'Ocp-Apim-Subscription-Key': '279841676caa4cb28777b993e09c4055',
    // Sandbox NAV2
    // 'Ocp-Apim-Subscription-Key': 'bba82a487de542c2b6a886abacce2742',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  });
}

export const environment: EnvironmentConfiguration = {
  name: 'dev',
  production: false,
  api: {
    // hostName: 'https://dotrezapi-akm.test.citilink.co.id/qg/dotrez',
    hostName: 'https://dotrezapi.test.qg.navitaire.com/qg/dotrez',
    // Sandbox NAV2
    // hostName: 'https://proxy.sandbox.navitaire.com/nav2',
    headersFn: defaultHeaders,
    tokenChannel: 'Web'
  },
  agency: {
    hostUrl: 'https://digitalretail-agency-demo.azurewebsites.net'
  },
  analytics: {
    gtmId: 'GTM-TN8PTV8W',
    gtmFieldValueCharLimit: 100,
    instrumentationKey: 'a528868e-918a-43ae-868e-41f2eff9e722',
    dynamics: {
      src: 'https://download.pi.dynamics.com/sdk/web/msei-0.min.js',
      name: 'EiJS',
      cfg: {
        ingestionKey:
          '42e8d0b5dc8443de9c7300bf6c2cf99b-c0b39432-2433-46d4-9d85-231e952486be-7473',
        autoCapture: {
          view: true,
          click: true
        }
      }
    }
  },
  paymentExtensions: {
    enabled: true,
    hostName: 'https://digitalapim.azure-api.net/ext-func-dev',
    functionKey: 'T3F7Ao62NzTXU/jaHh1Fkjy7/ja218hOLDSApxL8NaU9LIInvE6NXg==',
    subscriptionKey: '519cb9c0627940f386a816d606fd7412'
  },
  externalPayment: {
    url: 'https://sandbox-kit.espay.id/index/order',
    code: 'SGWCITILINK02'
  },
  cms: {
    enabled: false,
    url: 'http://149.129.238.119:5000',
    username: 'admin',
    key: 'CjHHvXQrrX22Kej'
  },
  insurance: {
    url: 'https://qg4p1skysales.sandbox-qg.com/api/Skysales/GetPriceInsurance',
    urlEmail: 'https://qg4p1skysales.sandbox-qg.com/api/Booking/CheckoutInsurance'
  },
  apiSky : {
    url: 'https://qg4p1skysales.sandbox-qg.com'
  },
  companyHomePage :{
    url : "https://wwwrebuild.sandbox-qg.com"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
