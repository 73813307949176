import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
  BoardingPassesCollectionv2,
  Booking,
  isFutureJourney,
  LiftStatus,
  NskTokenRequestv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingRetrieveByLastNameRequest,
  NskSettingsSelectors,
  SessionDataService,
  SettingsDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { WindowOpeningService } from '../common/window-opening.service';
import { CdkConfiguration } from '../config/cdk-configuration.model';
import { selectCdkConfiguration } from '../config/selectors';
import { RetrievalError } from './retrieval-error.enum';
import { QGBookingDataService, QGCheckinDataService } from '@customer/extensions';
import { Dictionary } from 'lodash';
import { SetBoardingPasses } from '../store/actions';
import { ValidatorsService } from '../forms/validators.service';
import { nskSettingsFeature } from '../store/settings/settings.reducer';

@Component({
  selector: 'navitaire-digital-pnr-retrieve',
  templateUrl: './pnr-retrieve.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['pnr-retrieve.scss']
})
export class PnrRetrieveComponent {
  @Input() pnrRetrieveTitle: string = 'Find Your Booking';
  @Input() mobileSearchButtonLabel: string = 'Search';
  @Input() mobileSearchIcon: string = 'icon_search';
  @Input() tacCheckbox?: boolean;
  @Input() source?: string;
  @Output() retrievingPnr: EventEmitter<null> = new EventEmitter<null>();
  @Output() pnrRetrieved: EventEmitter<boolean> = new EventEmitter<boolean>();

  lastName: string;
  recordLocator: string;
  error: RetrievalError;
  validError: boolean;
  tacCheckBoxValue: boolean;
  timeLimitErrorMsgs: string;

  RetrievalError: any = RetrievalError;

  protected get cdkConfiguration(): CdkConfiguration {
    return getObservableValueSync(this.store.select(selectCdkConfiguration));
  }

  protected get checkinCloseOffset(): number {
    return getObservableValueSync(this.store.select(NskSettingsSelectors.selectMinutesBeforeDepartureCheckinAllow)); 
  }

  protected get checkinOpenOffset(): number {
    return getObservableValueSync(this.store.select(NskSettingsSelectors.selectMinutesBeforeDepartureCheckinDisallow)); 
  }
  
  getRecordLocatorMask = (recordLocator: string) =>
    this.validatorsService.getRecordLocatorMask.apply(this.validatorsService, [
      recordLocator
      // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    ]);

  constructor(
    protected bookingDataService: QGBookingDataService,
    protected sessionDataService: SessionDataService,
    protected tripDataService: TripDataService,
    protected windowOpeningService: WindowOpeningService,
    protected store: Store,
    protected settingsDataService: SettingsDataService,
    protected checkinDataService: QGCheckinDataService,
    protected validatorsService: ValidatorsService,
    protected router: Router
  ) {}

  /**
   * Retrieves PNR from server and emits events before and after retrieval.
   */
  async retrieve(): Promise<void> {
  
    this.retrievingPnr.emit();
    this.error = await this.retrievePnrAndGetError();

    // this condition refrains from showing the already checked in error in retrieving the booking.
    if (!this.error || this.error === RetrievalError.AlreadyCheckedIn) {
      this.validError = false;
    } else {
      this.validError = true;
    }

    this.pnrRetrieved.emit(
      !this.error || this.error === RetrievalError.AlreadyCheckedIn
    );
  }

  protected async retrievePnrAndGetError(): Promise<RetrievalError> {
    const request: BookingRetrieveByLastNameRequest = {
      lastName: this.lastName,
      recordLocator: this.recordLocator
    };

    const tokenRequest: NskTokenRequestv2 = {
      applicationName: this.cdkConfiguration?.applicationName
    };
    await this.sessionDataService.newSessionIfRequired(tokenRequest);
    try {
      const response = await this.bookingDataService.retrieveBookingV2(request);
      const passengers = Object.values(response.body.data.passengers);
      
      // Check for passengers already checked in.
      // If all journeys are checked in, retrieve the boarding passes into the store and return AlreadyCheckedIn retrieval error.
      let checkedinPassengersBoardingPasses: Dictionary<BoardingPassesCollectionv2> =
        {};

      let allPassengersCheckedin = true;

      for (let journey of response.body.data.journeys) {
        let passengersCheckedInKeys: string[] = [];

        for (let segment of journey.segments) {
          for (let passenger of passengers) {
            if (
              segment.passengerSegment[passenger.passengerKey].liftStatus !==
              LiftStatus.CheckedIn &&
              segment.passengerSegment[passenger.passengerKey].liftStatus !==
              LiftStatus.Boarded
            ) {
              allPassengersCheckedin = false;
            } else if (
              !passengersCheckedInKeys.includes(passenger.passengerKey)
            ) {
              passengersCheckedInKeys.push(passenger.passengerKey);
            }
          }
        }

        if (passengersCheckedInKeys.length > 0) {
          checkedinPassengersBoardingPasses[journey?.journeyKey] =
            await this.checkinDataService.fetchBoardingPassesV2(
              passengersCheckedInKeys,
              journey.journeyKey
            );
        }
      }

      if (checkedinPassengersBoardingPasses) {
        this.store.dispatch(
          SetBoardingPasses({
            boardingPasses: checkedinPassengersBoardingPasses
          })
        );
      }

      if (
        !this.tripDataService.journeys.some(journey => isFutureJourney(journey))
      ) {
        return RetrievalError.NoFutureJourney;
      }

      if (allPassengersCheckedin) {
        return RetrievalError.AlreadyCheckedIn;
      }

      //For Additional error messages
      if(this.source == 'retrieve_checkin'){
        const hasError = this.checkRetrievalError(response.body.data);
        if(hasError !== RetrievalError.None) return hasError;
      }
      
    } catch (e) {
      this.sessionDataService.clearBookingState();
      //this checks if the checkin settings are null
      const checkinSettings = getObservableValueSync(
        this.store.select(nskSettingsFeature.selectCheckinSettings)
      );
      if (!checkinSettings) {
        await this.settingsDataService.fetchCheckinSettings();
      }
      const premiumSettings = getObservableValueSync(
        this.store.select(nskSettingsFeature.selectPremiumSettings)
      );
      if (!premiumSettings) {
        await this.settingsDataService.fetchPremiumSettings();
      }
      return RetrievalError.NotFound;
    }
  }

  checkRetrievalError(booking: Booking): RetrievalError {
    const journeys =  booking.journeys;
    const breakdown = booking.breakdown;

    //Check if balance due has value
    if(breakdown.balanceDue !== 0) return RetrievalError.HoldRestricted;
    
    //Check if international flight
    const isInternational = journeys.some(journey => journey.segments.some(segment => segment.international));
    if(isInternational){
      return RetrievalError.InternationalRestricted;
    }

    //Check if standby flight (ex: FOC)
    const isStandby = journeys.some(journey => journey.segments.some(segment => segment.isStandby));
    if(isStandby){
      return RetrievalError.FocRestricted;
    }

    //Check Max Min Time Checkin
    const firstDate: Date = new Date(journeys[0]?.designator.departure);
    const secondDate: Date = new Date();
    const milliDiff: number = firstDate.getTime() - secondDate.getTime();
    const totalSeconds = Math.floor(milliDiff / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    
    if(totalMinutes < this.checkinOpenOffset || totalMinutes > this.checkinCloseOffset){
      this.timeLimitErrorMsgs = RetrievalError.TimeLimitRestricted;
      return RetrievalError.TimeLimitRestricted;
    }

    return RetrievalError.None;
  }

  goToManageBooking(): void {
    if (this.error === RetrievalError.AlreadyCheckedIn || 
        this.error === RetrievalError.CheckinAlreadyClosed ||
        this.error === RetrievalError.TimeLimitRestricted 
      ) {

      this.router.navigate(['/mytrips/search']);
    }
  }
  
}
