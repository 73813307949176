<div [@fadeIn] class="background-color"></div>
<div [@fadeIn] class="background-image">
  <div
    [@delayFadeIn]
    class="background-image-container"
  ></div>
  <div class="background-image-gradient"></div>
</div>
<navitaire-digital-header-banner
  class="header-banner fixed"
></navitaire-digital-header-banner>
<navitaire-digital-header-breadcrumb
  navitaireDigitalCurrentRouteAttribute
  navitaireDigitalScrolledTop
  class="header-breadcrumb"
  [title]="(headerState$ | async)?.headerTitle"
  [showBackButton]="isBackButtonEnabled()"
  [showBreadcrumbs]="false"
  [showEdit]="false"
  [usingTabs]="(hasOneJourney$ | async) === false"
  [usingRowBelowHeader]="(passengerTabType$ | async) !== tabTypeEnum.PASSENGERS"
  [showTripInfo$]="false"
>
<div class="full-width">
    <div class="tabs">
      <navitaire-digital-extras-tabs
        *ngIf="(headerState$ | async)?.tripTabType === tabTypeEnum.JOURNEYS"
        [keys]="journeyKeys$ | async"
        [tabType]="tabTypeEnum.JOURNEYS"
        [selectedKey]="selectedJourneyKey$ | async"
        (selectionChanged)="setJourney($event)"
      ></navitaire-digital-extras-tabs>
      <navitaire-digital-extras-tabs
        *ngIf="(headerState$ | async)?.tripTabType === tabTypeEnum.LEGS"
        [keys]="legKeys$ | async"
        [selectedKey]="selectedLeg$ | async"
        [tabType]="tabTypeEnum.LEGS"
        (selectionChanged)="setLeg($event)"
      ></navitaire-digital-extras-tabs>
      <navitaire-digital-extras-tabs
        *ngIf="(headerState$ | async)?.tripTabType === tabTypeEnum.SEATMAPS"
        [keys]="seatmapKeys$ | async"
        [selectedKey]="selectedSeatmapKey$ | async"
        [tabType]="tabTypeEnum.SEATMAPS"
        (selectionChanged)="setSeatmap($event)"
      ></navitaire-digital-extras-tabs>
      <ng-container *ngIf="router.url === '/manage/hub'; else notManage">
        <navitaire-digital-passenger-select-tabs
        navitaireDigitalPassengerTabs
        *ngIf="(headerState$ | async)?.passengerTabType !== tabTypeEnum.NONE && verticalOffset >= 230"
        [keys]="passengerKeys$ | async"
        [selectedKey]="selectedPassengerKey$ | async"
        [showSeatSelection]="
          (headerState$ | async)?.tripTabType === tabTypeEnum.SEATMAPS
        "
        (selectionChanged)="setPassenger($event)"
        class="passenger-select-tabs"
      ></navitaire-digital-passenger-select-tabs>
      </ng-container>
        
        <ng-template #notManage>
          <navitaire-digital-passenger-select-tabs
          navitaireDigitalPassengerTabs
          *ngIf="(headerState$ | async)?.passengerTabType !== tabTypeEnum.NONE"
          [keys]="passengerKeys$ | async"
          [selectedKey]="selectedPassengerKey$ | async"
          [showSeatSelection]="
            (headerState$ | async)?.tripTabType === tabTypeEnum.SEATMAPS
          "
          (selectionChanged)="setPassenger($event)"
          class="passenger-select-tabs"
        ></navitaire-digital-passenger-select-tabs>
        </ng-template>
    </div>
  </div>
</navitaire-digital-header-breadcrumb>

<div
  class="bottom-row header-row mobile-flight-details"
  *ngIf="(headerState$ | async)?.headerTitle === 'Change flight'"
>
  <div class="details-wrapper">
    <navitaire-digital-origin-destination-display
      class="origin-destination-display h3"
      [origin]="availabilityRequestOrigin$ | async"
      [destination]="availabilityRequestDestination$ | async"
      [tripType]="availabilityRequestTripType$ | async"
    ></navitaire-digital-origin-destination-display>
    <navitaire-digital-flight-dates-display
      class="flight-dates-display h5"
      [departure]="availbilityRequestDeparture$ | async"
      [arrival]="availbilityRequestArrival$ | async"
    ></navitaire-digital-flight-dates-display>
  </div>
  <navitaire-digital-manage-edit-flight-search
    *ngIf="(headerState$ | async)?.showEditFlightButton"
    class="manage-edit-flight-search"
    [passengerCount]="passengersCount$ | async"
  ></navitaire-digital-manage-edit-flight-search>
</div>
<div>
  <div
  navitaireDigitalCurrentRouteAttribute
  class="under-header-container one-way manage-flow">
    <div
      class="under-header-wrapper one-way">
      <div class="selected-my-trip-wrapper">
        <navitaire-digital-manage-journey
          class="selected-my-trip"
          (checkinRequested)="navigateToCheckin()"
          (changeFlightRequested)="navigateToSelfServe()"
        ></navitaire-digital-manage-journey>
      </div>
    </div>
  </div>
  <navitaire-digital-passenger-select-tabs
  navitaireDigitalPassengerTabs
  *ngIf="(headerState$ | async)?.passengerTabType !== tabTypeEnum.NONE"
  [keys]="passengerKeys$ | async"
  [selectedKey]="selectedPassengerKey$ | async"
  [showSeatSelection]="
    (headerState$ | async)?.tripTabType === tabTypeEnum.SEATMAPS
  "
  (selectionChanged)="setPassenger($event)"
  class="passenger-select-tabs"
></navitaire-digital-passenger-select-tabs>
</div>
<div
#pageContent
  class="outlet-container manage-page-content"
  navitaireDigitalCurrentRouteAttribute
  [ngClass]="(pendingChanges$ | async) ? 'add-margin' : ''"
>
  <div
    [@routeAnimations]="prepareRoute(outlet)"
    class="extras-selection-container"
    [ngClass]="'one-way'"
  >
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>
<div class="container">
  <div class="row">
    <navitaire-digital-footer-bar
      class="footer-bar small"
      navitaireDigitalCurrentRouteAttribute
    ></navitaire-digital-footer-bar>
  </div>
</div>

<ng-template cdk-portal #bundleSelectDialog="cdkPortal">
  <navitaire-digital-bundle-select
    class="bundle-select"
    (closeDialog)="closeBundlesDialog()"
    [fares]="faresConfig"
    [journey]="bundleJourney"
    [index]="0"
    [bundleWasUnavailable]="bundleSellError"
    [unavailableBundleCode]="unavailableBundleCode"
    [changeBundle]="changeBundle"
    [selectedBundleCode]="selectedBundleCode"
  ></navitaire-digital-bundle-select>
</ng-template>