import {
  addCurrencyValues,
  BaggageAllowance,
  BundleConfiguration,
  BundleOffer,
  chargeTotal,
  ChargeType,
  getTotalChargeByPassengerType,
  Journey,
  Passenger,
  PassengerFee,
  Segment,
  Ssr
} from '@navitaire-digital/nsk-api-4.5.0';
import { Dictionary, first, keyBy } from 'lodash';
import {
  PassengerType
} from '../../flight-search/models/passengers-config.model';
import { BookingSummaryItem } from '../models/booking-summary-item.model';
import { getBookingSummaryFareCartItem } from './getBookingSummaryFareCartItem';
import { getDetailTaxesByPassengerType } from './getDetailTaxesByPassengerType';
import { BagTypeCollection } from '../../extras/models';
import { getPreTripDetailTaxInfant } from './getPreTripBookingsQuote';
import { getDetailSSR } from './getDetailSsr';

/** Creates booking summary item for passenger fares for each passenger type after trip sell */
export function getAfterTripSellBundleSummaryItems(
  passengers: Passenger[],
  journeys: Journey[],
  passengerTypeConfiguration: Dictionary<PassengerType>,
  selectedFareKeys: string[],
  availableBundles: BundleConfiguration[],
  selectedBundles: Dictionary<BundleOffer>,
  ssrDictionary: Dictionary<Ssr>,
  checkedBagConfig: string[],
  promoCode: string,
  bundleCodes: string[],
  infantFeeCode: string,
  selectPassengerFees: Dictionary<PassengerFee[]>,
  selectMealSsrs: Ssr[],
  selectSeatFeeCodes: string[],
  selectSurpriseSsrCodes: string[],
  selectBagConfig: BagTypeCollection,
  selectInsuranceSsrCodesConfig: string[],
  selectWrapperSsrCodesConfig: string[],
  selectLoungeSsrCodesConfig: string[],
  selectPassengerServiceSsrCodesConfig: string[],
  selectAllowances: BaggageAllowance[],
  selectedJourneyKey: string,
): BookingSummaryItem[] {
  const ssrItems: Dictionary<BookingSummaryItem> = {};
  const mealsItem: BookingSummaryItem = { name: 'meals', amount: 0, count: 0 };
  const seatItem: BookingSummaryItem = { name: 'seats', amount: 0, count: 0 };
  //const supriseInItem: BookingSummaryItem = { name: 'suprise in', amount: 0, count: 0 };
  const baggItem: BookingSummaryItem = { name: 'extra baggage', amount: 0, count: 0 };
  const insuranceItem: BookingSummaryItem = { name: 'insurance', amount: 0, count: 0 };
  const citiWarpItem: BookingSummaryItem = { name: 'citi wrapping', amount: 0, count: 0 };
  //const loungeItem: BookingSummaryItem = { name: 'lounge', amount: 0, count: 0 };
  const pssItems: BookingSummaryItem[] = [];
  const subTotal: BookingSummaryItem = { name: 'Subtotal', amount: 0, count: 0 };

  const loungeObject: BookingSummaryItem[] = [];
  const supriseInObject: BookingSummaryItem[] = [];

  let passengerInfants: Passenger[];
  let bookingSummarySection: BookingSummaryItem[] = [];

  Object.keys(passengerTypeConfiguration).forEach(passengerType => {
    const filteredPassengers = passengers?.filter(p => p.passengerTypeCode === passengerType);
    const paxTypeCount = filteredPassengers
      ?.map(s => s.passengerTypeCode).length;
    if (paxTypeCount <= 0) {
      return;
    }

    // Get Passenger Type
    let paxType: BookingSummaryItem = {
      name: `${passengerTypeConfiguration?.[passengerType]?.titleSingular}`,
      amount: 0
    };

    bookingSummarySection.push(paxType);


    // get promo
    const journeyDictionary = keyBy(journeys, journey => journey?.journeyKey);

    const promoAmount = getTotalChargeByPassengerType(
      journeyDictionary,
      passengerType,
      // Include ChargeType.PromotionDiscount when promotion section is not shown in credit cart section
      [ChargeType.Discount, ChargeType.PromotionDiscount]
    );

    //Get Base Fare of the following passenger types
    const baseFareFee = getBookingSummaryFareCartItem(
      passengerType,
      paxTypeCount,
      journeys
    );

    if(promoAmount > 0){
      baseFareFee.amount =  baseFareFee.amount - promoAmount;
    }

    bookingSummarySection.push(baseFareFee);

    // For Selected Bundle Fares
    if (selectedBundles) {
      selectedFareKeys?.forEach(fareKey => {
        const bundle = selectedBundles[fareKey]?.bundlePrices?.find(bundle => {
          return bundle?.passengerType
            ? bundle?.passengerType === passengerType
            : true;
        });

        //For Bundle Ssrs Items
        bundle?.bundleSsrPrices.forEach(ssr => {
          let key: string = ssr.ssrCode;
          let name: string = ssrDictionary?.[key]?.name;
          //if ssrCode belongs to checked bag,put them to the same section
          if (checkedBagConfig.includes(key)) {
            key = 'Checked bag';
            name = key;
          }

          if (bundleCodes?.includes(key) && ssr?.bundleSsrPrice === 0) {
            return;
          }

          ssrItems[key] = {
            name: `${paxTypeCount} x ${name}`,
            amount: ssr.bundleSsrPrice * paxTypeCount,
            count: paxTypeCount
          };
        });

        const bundleCode = selectedBundles[fareKey]?.bundleCode;
        const bundleName = availableBundles?.find(
          availableBundle =>
            availableBundle?.bundleCode === bundleCode && bundleCode !== null
        );
        const bundleItem: BookingSummaryItem = {
          name: `${paxTypeCount} x ${bundleName?.name} Bundle Fee`,
          amount: 0,
          count: 0
        };

        bundleItem.amount = addCurrencyValues(
          bundleItem?.amount,
          bundle?.feePrice * paxTypeCount
        );
        bundleItem.count = bundleItem?.count + paxTypeCount;
        if (bundleItem?.amount > 0) {
          bookingSummarySection.push(bundleItem);
        }

        bookingSummarySection = [
          ...bookingSummarySection,
          ...Object.values(ssrItems)
        ];
      });
    }

    // For Valued Added Tax
    let taxTotal = 0;
    const valuedAddedTax: BookingSummaryItem = {
      name: 'Taxes and Fees',
      amount: 0,
      count: 0
    };

    //const journeyDictionary = keyBy(journeys, journey => journey?.journeyKey);

    const paxTax = getTotalChargeByPassengerType(
      journeyDictionary,
      passengerType,
      [ChargeType.Tax]
    );

    const detailTaxes : BookingSummaryItem[] = getDetailTaxesByPassengerType(
      journeyDictionary,
      passengerType,
      paxTypeCount)

    if (paxTypeCount) {
      taxTotal = addCurrencyValues(taxTotal, paxTax * paxTypeCount);
      valuedAddedTax.amount = taxTotal;
    }

    //bookingSummarySection.push(valuedAddedTax);

    detailTaxes.forEach(element => {
      bookingSummarySection.push(element);
    });

    //For Discounts and Promo Code
    if (promoCode) {
      // Reset discount
      const discount: BookingSummaryItem = { name: 'Less Discount', amount: 0,  signToShow: '-'};
      let promoAmount = 0;

      promoAmount = getTotalChargeByPassengerType(
        journeyDictionary,
        passengerType,
        // Include ChargeType.PromotionDiscount when promotion section is not shown in credit cart section
        [ChargeType.Discount, ChargeType.PromotionDiscount]
      );

      if (promoAmount > 0) {
        discount.amount = promoAmount * paxTypeCount;
        //bookingSummarySection.push(discount);
      }
    }
  });

  /* Start: Infant passenger type */
  passengerInfants = passengers?.filter(
    passengers =>
      passengers?.infant !== null &&
      passengers?.infant?.name != null &&
      passengers?.infant?.name?.first != null &&
      passengers?.infant?.name?.last != null &&
      passengers?.infant?.fees?.filter(
        infantFee =>
          infantFee.serviceCharges.forEach(x => x.amount > 0) !== null
      )
  );

  if (passengerInfants !== null && passengerInfants?.length > 0) {
    const infantCount = passengerInfants.length;

    let infantType: BookingSummaryItem = { name: `Infant`, amount: 0 };
    bookingSummarySection.push(infantType);

    const passengerInfant = first(passengerInfants).infant;
    const flightRef = journeys[0].segments.map(s => s.flightReference); // check if need to loop in segment

    const infantFee = passengerInfant.fees.filter(
      fc => fc.code === infantFeeCode && flightRef.includes(fc.flightReference)
    );
    const infantFeeExcludeTax = infantFee.flatMap(s =>
      s.serviceCharges.filter(charge => charge.type !== ChargeType.Tax)
    );

    const infantBaseTotal = infantFeeExcludeTax?.reduce(chargeTotal, 0);

    const infantBaseFare: BookingSummaryItem = {
      amount: infantBaseTotal * infantCount,
      name: `${infantCount} x Base fare`
    };

    bookingSummarySection.push(infantBaseFare);

    // const infantFeeTaxes = infantFee.flatMap(s =>
    //   s.serviceCharges.filter(charge => charge.type === ChargeType.Tax)
    // );

    // const infantFeeVAT: BookingSummaryItem = {
    //   name: 'Taxes and Fees',
    //   amount: infantFeeTaxes?.reduce(chargeTotal, 0) * infantCount
    // };

    // //bookingSummarySection.push(infantFeeVAT);

    const index = journeys.findIndex(m => m.journeyKey == selectedJourneyKey);
    
    const detailTaxes : BookingSummaryItem[] = getPreTripDetailTaxInfant(
      index, passengers)

    detailTaxes.forEach(element => {
      bookingSummarySection.push(element);
    });
  }
  /* End: Infant passenger type */

  let flags = 0;
  Object.keys(selectPassengerFees).forEach(ObjectId => { 
    const objectList = selectPassengerFees[ObjectId];
    objectList.forEach(element => {

      let onThisJourney: Segment[] = [];

      for(var i in journeys[0].segments){
        const segment = journeys[0].segments[i];
        //Matching in segment
        if(segment.flightReference ==  element.flightReference){
          onThisJourney.push(segment);
        }
        //Matching
        else if(segment.legs.find(leg => leg.flightReference == element.flightReference) !== undefined){
          onThisJourney.push(segment);
        }
      }

     // const onThisJourney = journeys[0].segments.filter(s => s.flightReference == element.flightReference);
      
      if(onThisJourney.length > 0){ 
        if(selectMealSsrs.filter(m => m.ssrCode == element.ssrCode).length > 0){
          mealsItem.count ++; 
          mealsItem.name = mealsItem.count + " Meals";
          mealsItem.amount += element.serviceCharges.map(m => m.amount).reduce((acc, cur) => acc + cur, 0);

          flags ++;
        }else if(selectSeatFeeCodes.filter(ssr => ssr == element.code).length > 0){
          seatItem.count ++; 
          seatItem.name = seatItem.count + " Seat";
          seatItem.amount += element.serviceCharges.map(m => m.amount).reduce((acc, cur) => acc + cur, 0);
          
          flags ++;
        }else if(selectSurpriseSsrCodes.filter(ssr => ssr == element.ssrCode).length > 0){
          getDetailSSR(element, supriseInObject, ssrDictionary);
          
          flags ++;
        }else if(selectBagConfig.checked.filter(ssr => ssr == element.ssrCode).length > 0){
          baggItem.count ++; 
          baggItem.name = baggItem.count + " Extra Baggage";
          baggItem.amount += element.serviceCharges.map(m => m.amount).reduce((acc, cur) => acc + cur, 0);

          flags ++;
        }else if(selectInsuranceSsrCodesConfig.filter(ssr => ssr == element.ssrCode).length > 0){
          insuranceItem.count ++; 
          insuranceItem.name = insuranceItem.count + " Insurance";
         
          if(element.ssrCode == "SBG"){
            const getPaxBaggage = selectAllowances.find(allowance => allowance.passengerKey == ObjectId && allowance.journeyKey == selectedJourneyKey);
            insuranceItem.amount += element.serviceCharges.map(m => m.amount).reduce((acc, cur) => Math.abs(acc) + Math.abs(cur), 0) * getPaxBaggage.totalWeight;
          }else{
            insuranceItem.amount += element.serviceCharges.map(m => m.amount).reduce((acc, cur) => Math.abs(acc) + Math.abs(cur), 0);
          }

          flags ++;
        }else if(selectWrapperSsrCodesConfig.filter(ssr => ssr == element.ssrCode).length > 0){
          citiWarpItem.count ++; 
          citiWarpItem.name = citiWarpItem.count + " Citi Wrapping";
          citiWarpItem.amount += element.serviceCharges.map(m => m.amount).reduce((acc, cur) => acc + cur, 0);

          flags ++;
        }else if(selectLoungeSsrCodesConfig.filter(ssr => ssr == element.ssrCode).length > 0){
          getDetailSSR(element, loungeObject, ssrDictionary);

          flags ++;
        }else if(selectPassengerServiceSsrCodesConfig.filter(ssr => ssr == element.ssrCode).length > 0){
          let ssrPass = ssrDictionary?.[element.ssrCode];
          pssItems.push({count: 1, name:1+" "+ssrPass.name, amount: element.serviceCharges.map(m => m.amount).reduce((acc, cur) => acc + cur, 0)});

          flags ++;
        }
      }

    });
   })

  if(flags > 0){
    let extrasLabel: BookingSummaryItem = {
      name: `Extras`,
      amount: 0
    };
  
    bookingSummarySection.push(extrasLabel);
  }

  if(mealsItem.count > 0) bookingSummarySection.push(mealsItem);
  if(seatItem.count > 0) bookingSummarySection.push(seatItem);
  //if(supriseInItem.count > 0) bookingSummarySection.push(supriseInItem);
  //if(loungeItem.count > 0) bookingSummarySection.push(loungeItem);
  if(baggItem.count > 0) bookingSummarySection.push(baggItem);
  if(insuranceItem.count > 0) bookingSummarySection.push(insuranceItem);
  if(citiWarpItem.count > 0) bookingSummarySection.push(citiWarpItem);
  
  if(supriseInObject.length > 0){
    supriseInObject.forEach(element => {
      element.name =  element.count.toString() +" "+ element.name;
      bookingSummarySection.push(element);
    });
    
  } 
  if(loungeObject.length > 0){
    loungeObject.forEach(element => {
      element.name =  element.count.toString() +" "+ element.name;
      bookingSummarySection.push(element);
    });
    
  } 

  if(pssItems.length > 0) bookingSummarySection.push(...pssItems);

  bookingSummarySection.forEach(value => {
    if (value?.signToShow !== '-') {
      subTotal.amount += value?.amount;
    } else {
      subTotal.amount -= value?.amount;
    }
  });

  bookingSummarySection.push(subTotal);

  return bookingSummarySection;
}