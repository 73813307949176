<div *ngIf="(isMobileAppView$ | async) === false" class="wrapper">
  <div class="row">
    <footer class="bg-white border-top">
      <!-- Flex container -->
      <div class="container">
        <section>
          <div class="row footer-group-links">
            <div class="footer-link logo-phone">

              <div class="company-logo"></div>
              <ul class="list-unstyled">
                <li>
                  <span class="text-main" translate>footer.citilink.linka</span>
                </li>
                <li>
                  <span class="text-title-color">0811 1011 0808</span>
                </li>
                <li>
                  <span class="text-main" translate>footer.citilink.callcenter</span>
                </li>
                <li>
                  <span class="text-title-color">0804 1 080808</span>
                </li>
                <li>
                  <span class="text-main" translate>footer.citilink.international</span>
                </li>
                <li>
                  <span class="text-title-color">+6231 9920 8709</span>
                </li>
                <li>
                  <span class="text-main" translate>footer.citilink.cc_australia</span>
                </li>
                <li>
                  <span class="text-title-color">1300 781 747</span>
                </li>
                <li>
                  <span class="text-main" translate>footer.citilink.cc_newzealand</span>
                </li>
                <li>
                  <span class="text-title-color">0800 003 339</span>
                </li>
                <li>
                  <span class="text-main" translate>footer.citilink.email</span>
                </li>
                <li>
                  <span class="text-title-color">customercare@citilink.co.id</span>
                </li>
              </ul>
            </div>
            <div class="footer-link">
              <h5 class="text-title" translate>footer.about_us.text</h5>
              <ul class="list-unstyled">
                <li>
                  <a href="https://www.citilink.co.id/about-citilink" class="text-main" translate>footer.about_us.company_info</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/citilinkpedia/index" class="text-main" translate>footer.about_us.citilinkpedia</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/citilinkpedia/fleet-and-seating-plan" class="text-main" translate>footer.about_us.armada</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/route-map" class="text-main" translate>footer.about_us.route</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/fees" class="text-main" translate>footer.about_us.fee</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/uploads/1a807b1b-1249-45c1-a233-daabb0c8c0a2/list-station-citilink-per-280720.pdf" class="text-main" translate>footer.about_us.terminal</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/baggage-info" class="text-main" translate>footer.about_us.baggage</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/condition-of-carriage" class="text-main" translate>footer.about_us.carriage</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/international-condition" class="text-main" translate>footer.about_us.international</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/refund" class="text-main" translate>footer.about_us.refund</a>
                </li>
                <li>
                  <a href="https://recruitment.citilink.co.id" class="text-main" translate>footer.about_us.carreer</a>
                </li>
              </ul>
            </div>
            <div class="footer-link">
              <h5 class="text-title" translate>footer.product.text</h5>
              <ul class="list-unstyled">
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/menu-pre-book-meals" class="text-main" translate>footer.product.meals</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/green-zone" class="text-main" translate>footer.product.seat</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/excess-baggage" class="text-main" translate>footer.product.baggage</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/insurance" class="text-main" translate>footer.product.assurance</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/citilink-lounge" class="text-main" translate>footer.product.lounge</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/greenup" class="text-main" translate>footer.product.upgrade_seat</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/passenger-assistance" class="text-main" translate>footer.product.passenger</a>
                </li>
                <li>
                  <a href="https://linkpass.citilink.co.id" class="text-main" translate>footer.product.linkpass</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/linksport" class="text-main" translate>footer.product.linksport</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/surprise-in" class="text-main" translate>footer.product.surprisein</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/product/citilink-cargo" class="text-main" translate>footer.product.cargo</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/charter" class="text-main" translate>footer.product.carter</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/bundling-product" class="text-main" translate>footer.product.bundling</a>
                </li>
                <li>
                  <a href="https://linkshop.citilink.co.id" class="text-main" translate>footer.product.shop</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/dine-shop-catalog" class="text-main" translate>footer.product.dine</a>
                </li>

                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/wrapping-baggage" class="text-main" translate>footer.product.warpping</a>
                </li>
              </ul>
            </div>
            <div class="footer-link">
              <h5 class="text-title" translate>footer.login.text</h5>
              <ul class="list-unstyled">
                <li>
                  <a href="https://book.citilink.co.id/LoginAgency.aspx?culture=en-US" class="text-main" translate>footer.login.agent</a>
                </li>
                <li>
                  <a href="https://book.citilink.co.id/LoginCorporateAgent.aspx?culture=en-US"
                    class="text-main" translate>footer.login.company</a>
                </li>
                <li>
                  <a href="https://groupbook.citilink.co.id/#/" class="text-main" translate>footer.login.groupbook</a>
                </li>
                <li>
                  <a href="https://book.citilink.co.id/RegisterAgency.aspx?culture=id-ID" class="text-main" translate>footer.login.agency</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/informations/cargo-registration" class="text-main" translate>footer.login.cargo</a>
                </li>
                <li>
                  <a href="http://cargo.citilink.co.id/" class="text-main" translate>footer.login.login_cargo</a>
                </li>
                <li>
                  <a href="https://cargo.garuda-indonesia.com/" class="text-main" translate>footer.login.shipment</a>
                </li>
                <li>
                  <a href="https://e-proc.citilink.co.id/" class="text-main" translate>footer.login.procurement</a>
                </li>
              </ul>
            </div>
            <div class="footer-link">
              <h5 class="text-title" translate>footer.linkmiles.text</h5>
              <ul class="list-unstyled">
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/linkmiles/index" class="text-main" translate>footer.linkmiles.about</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/linkmiles/earning-miles" class="text-main" translate>footer.linkmiles.earning</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/redemption/main" class="text-main" translate>footer.linkmiles.redeem</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/linkmiles/faq" class="text-main" translate>footer.linkmiles.faq</a>
                </li>
              </ul>
            </div>

          </div>
          <div class="row footer-group-apps">
            <!--<div class="footer-link spacer"></div>-->
            <div class="footer-link">
              <h5 class="text-title">Awards</h5>
              <div class="award-links">
                <div>
                  <!-- <img src="https://citilink.co.id/uploads/f9586c0c-ca6a-44a3-bbea-2716ef3beb2b/tripadvisor_140.png"
                    class="img-thumbnail float-right mr-1">
                  <img src="https://citilink.co.id/uploads/cd023192-e9f8-4b36-8698-7f27490aa023/apex_140.jpg"
                    class="img-thumbnail float-right mr-1"> -->
                  <img src="/assets/logo/skytrax-logo-covid.png"
                    class="img-thumbnail float-right mr-1">
                  <img src="/assets/logo/skytrax-logo-stars.png"
                    class="img-thumbnail float-right mr-1">
                </div>
              </div>
            </div>
            <div class="footer-link">
              <h5 class="text-title">Follow Me</h5>
              <div>
                <div>
                  <a class="btn btn-outline-light btn-floating btn-block m-0" href="https://web.facebook.com/citilink?_rdc=1&amp;_rdr"
                    title="Facebook" role="button">
                    <!--<i class="fab fa-facebook"></i>-->
                    <i><img src="/assets/logo/facebook.png"
                    class="img-thumbnail float-right mr-1 icon-soscial"></i>
                  </a>
                  <a class="btn btn-outline-light btn-floating btn-block m-0" href="https://twitter.com/citilink" title="Twitter"
                    role="button">
                    <!--<i class="fab fa-twitter"></i>-->
                    <img src="/assets/logo/twitter.png"
                    class="img-thumbnail float-right mr-1 icon-soscial">
                  </a>
                  <a class="btn btn-outline-light btn-floating btn-block m-0" href="https://www.instagram.com/citilink/" title="Instagram"
                    role="button">
                    <!--<i class="fab fa-instagram instagram"></i>-->
                    <img src="/assets/logo/instagram.png"
                    class="img-thumbnail float-right mr-1 icon-soscial">
                  </a>
                  <a class="btn btn-outline-light btn-floating btn-block m-0" href="https://www.youtube.com/citilinkindonesia"
                    title="Youtube" role="button">
                    <!--<i class="fab fa-youtube youtube"></i>-->
                    <img src="/assets/logo/youtube.png"
                    class="img-thumbnail float-right mr-1 icon-soscial">
                  </a>
                  <a class="btn btn-outline-light btn-floating btn-block m-0" href="https://www.citilink.co.id/{{currentLocale}}/linkers-magazine"
                    title="Telegram" role="button">
                    <!--<i class="fa fa-book-open telegram"></i>-->
                    <img src="/assets/logo/magazine.png"
                    class="img-thumbnail float-right mr-1 icon-soscial">
                  </a>
                </div>
              </div>
            </div>
            <div class="footer-link">
              <h5 class="text-title" translate>Contact Us</h5>


              <ul class="list-unstyled">
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/informations/sales-office" class="text-main">Sales Offices</a>
                </li>
                <li>
                  <a href="https://www.citilink.co.id/{{currentLocale}}/faq" class="text-main">FAQ</a>
                </li>
              </ul>
            </div>
            <div class="footer-link">
              <h5 class="text-title">Download Citilink App</h5>
              <div class="app-links">
                <div>
                  <img
                  src="https://www.citilink.co.id/uploads/f29d3635-be7e-42f4-a59c-990857515f51/barcode-citilink-new-mobile-app--android.jpeg"
                  class="img-thumbnail float-right mr-1 barcode-image-size">
                <img
                  src="https://www.citilink.co.id/uploads/490574a9-a888-4cdd-bf4b-fa2aae5b5c10/QR_Apple1.jpeg"
                  class="img-thumbnail float-left ml-1 barcode-image-size">
                <img src="/assets/logo/google-play.png"
                  class="rounded float-right img-thumbnail mr-1 mt-2 barcode-image-size">
                <img src="/assets/logo/app-store.png"
                  class="rounded float-left img-thumbnail ml-1 mt-2 barcode-image-size">
                </div>
              </div>
            </div>

          </div>
        </section>

      </div>
      
      <!-- Copyright -->
      
      <!-- Copyright -->
    </footer>
  </div>
</div>