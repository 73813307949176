
<div class="flex-wrapper ">
  <div class="manage-hub-container">
  
    <div class="extra-selection">
      <div class="left-column">
        <!-- SEATS -->
        <navitaire-digital-ssr-select-layout
          class="ssr-select-layout"
          [price]="lowestSeatPrice$ | async"
          [selection]="soldSeatsLabels$ | async"
          [disable]="(isSeatmapAvailable$ | async) === false"
          [hasNoSelection]="checkIfSeatIsAssigned$ | async"
          [description]="'Looking for comfortable flight? Pick your seat here'"
          [includeInfant]="infantFromPassenger$ | async"
          title="Seats"
          data-cy="seatsButton"
          imageClass="icon_seat_hub"
          iconClass="icon_seat_hub"
          [routerLink]="
            (isSeatmapAvailable$ | async) === true ? ['../seats'] : null
          "
          tabindex="-1"
          priceLabel="Starting from "
        >
        <button
        content-control
        *ngIf="(isSeatmapAvailable$ | async) !== false"
        class="navitaire-digital-button tertiary small"
      >
        Select
      </button>
        </navitaire-digital-ssr-select-layout>

        <!-- BAGS -->
        <navitaire-digital-ssr-select-layout
          class="ssr-select-layout multiple"
          title="Baggages"
          data-cy="bagsButton"
          [price]="lowestBagPrice$ | async"
          [selection]="soldBagsLabels$ | async"
          [hasNoSelection]="(soldBagsLabels$ | async).length === 0"
          [disable]="(isBagsAvailable$ | async) === false"
          imageClass="icon_Bags_Hub"
          iconClass="icon_Bags_Hub"
          [routerLink]="(isBagsAvailable$ | async) ? ['../bags'] : null"
          tabindex="-1"
          [description]="'Additional Baggage? Buy here to get special price'"
          priceLabel="Starting from "
    >
      <button
        content-control
        class="navitaire-digital-button tertiary small"
        *ngIf="isBagsAvailable$ | async"
      >
        Select
      </button>
        </navitaire-digital-ssr-select-layout>

        <!-- MEALS -->
        <navitaire-digital-ssr-select-layout
          class="ssr-select-layout multiple"
          title="Meals"
          data-cy="mealsButton"
          [price]="lowestMealPrice$ | async"
          [selection]="soldMealsLabels$ | async"
          [hasNoSelection]="(soldMealsLabels$ | async).length === 0"
          [disable]="(isMealsAvailable$ | async) === false"
          imageClass="icon_Meals_Hub"
          iconClass="icon_Meals_Hub"
          [routerLink]="(isMealsAvailable$ | async) ? ['../meals'] : null"
          tabindex="-1"
          [description]="'Feeling Hungry? Buy here to get special price'"
        >
          <button 
            content-control 
            class="navitaire-digital-button tertiary small" 
            *ngIf="isMealsAvailable$ | async"
          >
            Select
          </button>
        </navitaire-digital-ssr-select-layout>

        <!-- INSURANCE -->
        <navitaire-digital-ssr-select-layout
        class="ssr-select-layout multiple"
        title="Travel Insurance"
        data-cy="insuranceButton"
        [price]="lowestInsurancePrice$ | async"
        [selection]="soldInsuranceLabels$ | async"
        [hasNoSelection]="(soldInsuranceLabels$ | async).length === 0"
        [disable]="(isInsurancesAvailable$ | async) === false"
        imageClass="icon_Insurance_Hub"
        iconClass="icon_Insurance_Hub"
        [routerLink]="(isInsurancesAvailable$ | async) ? ['../insurance'] : null"
        tabindex="-1"
        [description]="'Protect your Journey, pick your insurance here'"
        priceLabel="Starting from "
        >
          <button
            content-control
            class="navitaire-digital-button tertiary small"
            *ngIf="isInsurancesAvailable$ | async"
          >
            Select
          </button>
        </navitaire-digital-ssr-select-layout>

        <!-- SURPRISE IN -->
        <navitaire-digital-ssr-select-layout
        class="ssr-select-layout multiple"
        [price]="lowestSurprisePrice$ | async"
        [description]="'A special surprise for your special someone, in flight!'"
        [selection]="soldSurpriseLabels$ | async"
        [disable]="(isSurpriseInAvailable$ | async) === false && (surpriseInSoldSsrs$ | async).length === 0"
        [hasNoSelection]="(soldSurpriseLabels$ | async).length === 0"
        title="Surprise In"
        data-cy="surpriseButton"
        imageClass="icon_Surprise_Hub"
        iconClass="icon_Surprise_Hub"
        tabindex="-1"
        priceLabel="Starting from "
        [routerLink]="(isSurpriseInAvailable$ | async) || (surpriseInSoldSsrs$ | async).length > 0 ? ['../surprises'] : null"
      >
        <button content-control  class="navitaire-digital-button tertiary small">
      Select
        </button>
      </navitaire-digital-ssr-select-layout>
  
        <!-- CITIWRAPPING -->
        <navitaire-digital-ssr-select-layout
        class="ssr-select-layout multiple"
        title="Wrapping Baggage"
        data-cy="bagsButton"
        [price]="lowestWrapperPrice$ | async"
        [selection]="soldWrapperLabels$ | async"
        [hasNoSelection]="(soldWrapperLabels$ | async).length === 0"
        [disable]="(isWrapperAvailable$ | async) === false"
        imageClass="icon_Wrapping_Hub"
        iconClass="icon_Wrapping_Hub"
        [routerLink]="(isWrapperAvailable$ | async) ? ['../citiWrapping'] : null"
        tabindex="-1"
        [description]="'Secure your baggage with Wrapping Baggage'"
        priceLabel="Starting from "
        >

          <button
            content-control
            class="navitaire-digital-button tertiary small"
            *ngIf="isWrapperAvailable$ | async"
          >
            Select
          </button>
        </navitaire-digital-ssr-select-layout>

        <!-- LOUNGE -->
        <navitaire-digital-ssr-select-layout
          class="ssr-select-layout multiple"
          title="Airport Lounge"
          data-cy="loungeButton"
          [price]="lowestLoungePrice$ | async"
          [selection]="soldLoungeLabels$ | async"
          [hasNoSelection]="(soldLoungeLabels$ | async).length === 0"
          [disable]="(isLoungesAvailable$ | async) === false"
          imageClass="icon_Lounge_Hub"
          iconClass="icon_Lounge_Hub"
          [routerLink]="(isLoungesAvailable$ | async) ? ['../lounge'] : null"
          tabindex="-1"
          [description]="'Relax and enjoy Lounge facility before boarding'"
          priceLabel="Starting from "
        >
          <button
            content-control
            class="navitaire-digital-button tertiary small"
            *ngIf="isLoungesAvailable$ | async"
          >
            Select
          </button>
        </navitaire-digital-ssr-select-layout>

        <!-- PASSENGER SERVICES -->
        <navitaire-digital-ssr-select-layout
          class="ssr-select-layout multiple"
          title="Passenger Services"
          data-cy="passengerServiceButton"
          [price]="lowestPassengerServicePrice$ | async"
          [selection]="soldPassengerServiceLabels$ | async"
          [hasNoSelection]="(soldPassengerServiceLabels$ | async).length === 0"
          [disable]="(isPassengerServicesAvailable$ | async) === false"
          imageClass="icon_Passenger_Hub"
          iconClass="icon_Passenger_Hub"
          [routerLink]="
            (isPassengerServicesAvailable$ | async) ? ['../passengerservice'] : null
          "
          tabindex="-1"
          [description]="'Enjoy simplicity on your departure and arrival process'"
          priceLabel="Starting from "
        >
          <button
            content-control
            class="navitaire-digital-button tertiary small"
            *ngIf="isPassengerServicesAvailable$ | async"
          >
            Select
          </button>
        </navitaire-digital-ssr-select-layout>

        <navitaire-digital-select-additional-ssrs></navitaire-digital-select-additional-ssrs>
  
        <!-- CHANGE FLIGHT -->
        <!-- <navitaire-digital-change-flight-card
          *ngIf="
            (notCheckedIn$ | async) === true &&
            (hasPendingFlightChanges$ | async) === false
          "
          class="change-flight-card"
          (click)="changeFlight()"
          data-cy="changeFlightButton"
          tabindex="-1"
        >
        </navitaire-digital-change-flight-card> -->
  
        <button
          class="row medium cancel-button cancel navitaire-digital-button"
          (click)="openCancelOverlay()"
          data-cy="cancelFlightButton"
          translate
          *ngIf="showCancelButton$ | async"
          [attr.aria-label]="'Cancel Flight' | translate"
        >
          Cancel Flight
        </button>
      </div>
    </div>
  </div>
  
  <ng-template cdk-portal #cancelFlightModal="cdkPortal">
    <navitaire-digital-modal
      (closeModal)="closeOverlay()"
      title="Cancel Flight"
      class="modal-overlay modal-small"
      [displayClose]="false"
    >
      <navitaire-digital-flight-cancel-dialog
        class="cancel-flight-modal simple-modal"
        (closeCancelFlightModal)="closeOverlay()"
      ></navitaire-digital-flight-cancel-dialog>
    </navitaire-digital-modal>
  </ng-template>
  <ng-template cdk-portal #changeFlightModal="cdkPortal">
    <navitaire-digital-modal
      (closeModal)="closeOverlay()"
      title="Reset Extras"
      class="modal-overlay"
      [displayClose]="false"
    >
      <div class="change-flight-container">
        <navitaire-digital-flight-change-dialog
          class="change-flight-modal simple-modal"
          (closeDialog)="closeOverlay()"
          (changeFlight)="clearNskSelectionsAndNavigate()"
        ></navitaire-digital-flight-change-dialog>
      </div>
    </navitaire-digital-modal>
  </ng-template>
  
  <ng-template cdk-portal #notificationsSettingsModal="cdkPortal">
    <navitaire-digital-modal
      (closeModal)="closeOverlay()"
      title="Notifications"
      class="modal-overlay"
    >
      <navitaire-digital-notification-settings></navitaire-digital-notification-settings>
    </navitaire-digital-modal>
  </ng-template>
  
  <navitaire-digital-booking-summary-overview
    navitaireDigitalBookingSummaryView
    class="booking-summary-section"
    [origin]="selectedJourneyOrigin"
    [destination]="selectedJourneyDestination"
    [tripType]="tripType"
    [showPriceDetails]="showPriceDetails"
    [displayType]="'MANAGE'"
    (continueToNextPage)="continue()"
    [class.has-changes]="(hasPendingChanges$ | async)"
  ></navitaire-digital-booking-summary-overview>
</div>