import { Directive, HostListener, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { QGSessionDataService } from '@customer/extensions';
import { CdkSnapshotActions } from '../snapshot/store/actions';
import { COMPANY_WEBSTITE_URL } from 'projects/extensions/src/lib/config/injection.tokens';
import { PageBusyService } from './page-busy.service';
import { RedirectService } from './url-redirect.service';

@Directive({
  selector: '[navitaireDigitalStartOver]'
})
export class StartOverDirective {
  @Input()
  retainLowFareSettings: boolean = false;

  @Input()
  retainPremiumSettings: boolean = false;

  @HostListener('click')
  @HostListener('keydown.enter')
  goToHome(): void {
    // change home
    // this.router.navigateByUrl('/home/search').then(navigationComplete => {
    //   if (navigationComplete) {
    //     this.sessionDataService.clearBookingState();
    //     this.sessionDataService.clearTripState(this.retainLowFareSettings, this.retainPremiumSettings);
    //     this.store.dispatch(CdkSnapshotActions.clearsnapshot());
    //   }
    // });
    this.pageBusyService.showLoadingSpinner();
    this.sessionDataService.clearBookingState();
    this.sessionDataService.clearTripState(this.retainLowFareSettings, this.retainPremiumSettings);
    this.store.dispatch(CdkSnapshotActions.clearsnapshot());
    this.redirectService.redirect(this.skysalesApiUrl);
  }

  constructor(
    protected sessionDataService: QGSessionDataService,
    protected router: Router,
    protected store: Store,
    @Inject(COMPANY_WEBSTITE_URL) protected skysalesApiUrl: string,
    protected pageBusyService: PageBusyService,
    protected redirectService: RedirectService
  ) {}
}
