  <div class="boarding-content"  [ngClass]="(isModalBoardingPassClicked$ | async) ? '' : 'auto-height'">
    <ng-container *ngFor="let boardingPasses of boardingPasses$ | boardingpassLegFilter | keyvalue">
      <div
        class="single-boarding-pass"
        *ngFor="let boardingPass of boardingPasses.value.boardingPasses"
      >
        <navitaire-digital-boarding-pass
          *ngIf="boardingPasses.key === (journeyKeySelected$ | async)"
          [boardingPass]="boardingPass"
          [journeyBoardingPasses]="boardingPasses.value"
          [journeyKey]="(journeyKeySelected$ | async)"
          [baggageAllowance]="(baggageAllowance$)"
          class="boarding-pass"
        ></navitaire-digital-boarding-pass>
      </div>
    </ng-container>
  </div>


