import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileDataService } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageAction } from '../../../analytics/actions';
import { PageEventType } from '../../../analytics/models/page-event-type';
import { delayFadeIn } from '../../../common/animations';
import { PageBusyService } from '../../../common/page-busy.service';

@Component({
  selector: 'navitaire-digital-my-trips-retrieve-page',
  templateUrl: './my-trips-retrieve-page.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [delayFadeIn],
  styleUrls: ['my-trips-select-page.scss']
})
export class MyTripsRetrievePageComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();

  constructor(
    protected profileDataService: ProfileDataService,
    protected pageBusyService: PageBusyService,
    protected router: Router,
    protected store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      PageAction({
        event: PageEventType.HomeMyTrips
      })
    );

    this.profileDataService.loggedIn$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loggedIn => {
        if (loggedIn && !this.router.url.includes('search')) {
          this.router.navigate(['/mytrips/select']);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  busy(): void {
    this.pageBusyService.showLoadingSpinner();
  }

  handlePnrRetrieved(retrievedSuccessfully: boolean): void {
    this.pageBusyService.hideLoadingSpinner();
    if (retrievedSuccessfully) {
      this.router.navigate(['/mytrips/search']);
    }
  }
}
