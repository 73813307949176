import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  BoardingPassLeg,
  BoardingPassSegment,
  BoardingPassv2,
  BaggageAllowance
} from '@navitaire-digital/nsk-api-4.5.0';
import { BookingDataService } from '@navitaire-digital/web-data-4.5.0';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { first } from 'lodash';
import { Store } from '@ngrx/store';
import { createCanvas } from 'canvas'; 
import { WindowOpeningService } from '../../common/window-opening.service';
import { NavitaireDigitalOverlayService } from '../../common/overlay.service';
import { BoardingPassClickAction } from '../../analytics/actions/check-in/boarding-pass-click-action';
import { SetBoardingPassClicked } from '../../store/actions';
import { selectBoardingPassClicked } from '../../store';
import {
  selectCheckedBagsConfig,
  selectSsrExcludeConfig
} from '../../config/selectors';
import { WindowRefService } from '../../common/window-ref.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Advertisement, BoadingPassService } from 'projects/extensions/src/lib/services/citilink-api';
import { Observable, Subscription } from 'rxjs';
@Component({
  selector: 'navitaire-digital-boarding-pass',
  templateUrl: './boarding-pass.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['boarding-pass.scss']
})
export class BoardingPassComponent implements OnInit {
  @Input() boardingPass: BoardingPassv2;
  @Input() journeyBoardingPasses: BoardingPassv2[];
  @Input() journeyKey: string;
  @Input() baggageAllowance: BaggageAllowance[];
  // Helper models based on the boarding pass.
  firstSegment: BoardingPassSegment;
  firstLeg: BoardingPassLeg;
  recordLocator: string = this.bookingDataService?.booking?.recordLocator;
  services:string[] = [];
  baggageSsrs:string[]=[];
  baggageLabel:string=null;
  checkedBagCodes:string[]=[];
  excludeSsr:string[]=[];
  canvas:any;
  isModalBoardingPassClicked:boolean = getObservableValueSync(this.store.select(selectBoardingPassClicked));
  advertisement$: Observable<Advertisement>;
  subscription: Subscription;
  
  constructor(    
    protected windowOpeningService: WindowOpeningService,
    protected store: Store,
    protected bookingDataService: BookingDataService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected windowRefService: WindowRefService,
    protected boadingPassService: BoadingPassService,
    private sanitizer:DomSanitizer,
    ) {
    }

  async ngOnInit(): Promise<void> {
    if (this. boardingPass) {
      if (this.boardingPass.segments) {
        this.firstSegment = first(this.boardingPass.segments);
        if (this.firstSegment) {
          this.firstLeg = first(this.firstSegment.legs);
        }
      }
    }
    
    this.excludeSsr = getObservableValueSync(this.store.select(selectSsrExcludeConfig));
    this.getBaggageLabel();
    this.filterServices();
    

    this.advertisement$ = this.boadingPassService.getAdvertisingImage(this.firstLeg.designator.origin, this.firstLeg.designator.destination);
  }

 
  print(): void {
    if (this.windowRefService.window) {
      this.windowRefService.window.print();
    }
  }

  openPrintBP(): void {
    this.store.dispatch(BoardingPassClickAction());
    this.store.dispatch(
      SetBoardingPassClicked({ boardingPassClicked: false })
    );
    this.windowOpeningService.openWindow('boardingpass');
    this.overlayService.hide();
  }

  generateBarcode(){   
    const PDF417 = require("pdf417-generator")
    this.canvas = createCanvas(488,112,'svg');
    PDF417.draw(this.boardingPass?.passenger?.barCode, this.canvas, 2, -1, window.devicePixelRatio * 2);
    //PDF417.draw(this.boardingPass?.passenger?.barCode, this.canvas);
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.canvas.toDataURL());
  }


  getBaggageLabel(): void{
    var bagsConfig = getObservableValueSync(this.store.select(selectCheckedBagsConfig));
    //var ssrCodes:string[] = [];
    // if(this.firstLeg.ssrs?.length > 0){
    //   this.firstLeg.ssrs?.forEach(ssr => {
    //     ssrCodes.push(ssr.ssrCode);
    //   });
    // }
    var checkedBagsConfig = bagsConfig.checkedBags;
    // var bagsLabel = 'null';
    
    // let passengerCheckedBagsAllowanceTotal = 0;

    if (checkedBagsConfig) {
      checkedBagsConfig.map((checkedBagsConfig) => {
        //Store baggage codes for future use
        if(!this.checkedBagCodes.includes(checkedBagsConfig.checkedBagCode)){
          this.checkedBagCodes.push(checkedBagsConfig.checkedBagCode);
        }
       
        // if (ssrCodes.includes(checkedBagsConfig?.checkedBagCode)) {
        //   passengerCheckedBagsAllowanceTotal += checkedBagsConfig.allowance
        // }
      })
    }
    // if(passengerCheckedBagsAllowanceTotal > 0){
    //   bagsLabel = passengerCheckedBagsAllowanceTotal+'kg'
    // }else{
    //   bagsLabel = 'None';
    // }
    // this.baggageLabel = bagsLabel;

    if(this.baggageAllowance.length > 0){
      this.baggageLabel = this.baggageAllowance[0].totalWeight + "kg";
    }else{
      this.baggageLabel = "-";
    }
    
  }

  filterServices(): void{
    this.firstLeg.ssrs.forEach(ssr => {
      if(!this.checkedBagCodes.includes(ssr.ssrCode) && !this.excludeSsr.includes(ssr.ssrCode)){
        this.services.push(ssr.ssrCode);
      }
    });
  }
}
