import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Passenger } from '@navitaire-digital/nsk-api-4.5.0';

@Component({
  selector: 'navitaire-digital-passenger-list',
  templateUrl: './passenger-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['passenger-list.scss']
})
export class PassengerListComponent {
  @Input() passengers: Passenger[];
  @Input() showTravelDocs: boolean = false;
  @Input() showCheckBox?: boolean = false;

  /** This is used for check-in */
  @Input() passengerKeysCheckinSelections?: string[];
  @Input() passengerKeysSeatedInRestrictedRows?: string[];
  @Input() checkedInPassengerKeys?: string[];
  @Input() boardedPassengerKeys?: string[];

  @Output()
  passengerKeyEmitter: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  boardingPassEmitter: EventEmitter<string> = new EventEmitter<string>();

  emitPassengerKey(passengerKey: string): void {
    this.passengerKeyEmitter.emit(passengerKey)
  }

  emitBoardingPass(passengerKey: string): void {
    this.boardingPassEmitter.emit(passengerKey)
  }

  /** Checks if selected passenger is already in
   * passenger Check in selection
   */
  isSelected(passengerKey: string): boolean {
    if (this.passengerKeysCheckinSelections) {
      return this.passengerKeysCheckinSelections
        .some(passengerKeyCheckinSelection => passengerKeyCheckinSelection === passengerKey);
    }
    return false;
  }
}
