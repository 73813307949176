<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.SELF_SERVE_DELAY"
  class="delay-info-wrapper"
>
  <div class="delayed-warning">
    <div translate>This flight has been delayed.</div>
    <div translate>Would you like to change your flight?</div>
  </div>
  <div class="buttons">
    <button
      class="navitaire-digital-button tertiary medium"
      [disabled]="false"
      (click)="keepDelayedFlight()"
      data-cy="keepDelayedFlightButton"
      [attr.aria-label]="'Keep this flight' | translate"
      translate
    >
      Keep this flight
    </button>
    <button
      class="navitaire-digital-button primary medium"
      [disabled]="false"
      (click)="changeDelayedFlight()"
      data-cy="changeDelayedFlightButton"
      [attr.aria-label]="'Change flight' | translate"
      translate
    >
      Change
    </button>
  </div>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.SELF_SERVE_TIME_CHANGE"
  class="delay-info-wrapper"
>
  <div class="delayed-warning">
    <div class="top">
      <span translate>Flight</span>
      <span *ngFor="let i of identifiers; last as isLast">
        {{ i }}
        <span *ngIf="!isLast">,</span>
      </span>
      ({{ designator?.origin }} - {{ designator?.destination }})
      <span translate>has been updated.</span>
    </div>
    <div translate>Would you like to change your flight?</div>
  </div>
  <div class="buttons">
    <button
      class="navitaire-digital-button tertiary medium"
      [disabled]="false"
      (click)="keepDelayedFlight()"
      data-cy="keepDelayedFlightButton"
      [attr.aria-label]="'Keep this flight' | translate"
      translate
    >
      Keep this flight
    </button>
    <button
      class="navitaire-digital-button primary medium"
      [disabled]="false"
      (click)="changeDelayedFlight()"
      data-cy="changeDelayedFlightButton"
      [attr.aria-label]="'Change flight' | translate"
      translate
    >
      Change
    </button>
  </div>
</div>

<div
  *ngIf="
    (displayOption$ | async) === displayOptionEnum.SELF_SERVE_SCHEDULE_CHANGE
  "
  class="delay-info-wrapper"
>
  <div class="delayed-warning">
    <div class="top">
      <span translate
        >Your flight has been cancelled and you have been moved to flight</span
      >
      <span *ngFor="let i of identifiers; last as isLast">
        {{ i }}
        <span *ngIf="!isLast">,</span>
      </span>
      ({{ designator?.origin }} - {{ designator?.destination }}).
    </div>
    <div translate>Would you like to change your flight?</div>
  </div>
  <div class="buttons">
    <button
      class="navitaire-digital-button tertiary medium"
      [disabled]="false"
      (click)="keepDelayedFlight()"
      data-cy="keepDelayedFlightButton"
      [attr.aria-label]="'Keep this flight' | translate"
      translate
    >
      Keep this flight
    </button>
    <button
      class="navitaire-digital-button primary medium"
      [disabled]="false"
      (click)="changeDelayedFlight()"
      data-cy="changeDelayedFlightButton"
      [attr.aria-label]="'Change flight' | translate"
      translate
    >
      Change
    </button>
  </div>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.SELF_SERVE_IROP"
  class="delay-info-wrapper"
>
  <div class="delayed-warning">
    <div class="top">
      <span translate>You have been moved to flight</span>
      <span *ngFor="let i of identifiers; last as isLast">
        {{ i }}
        <span *ngIf="!isLast">,</span>
      </span>
      ({{ designator?.origin }} - {{ designator?.destination }}).
    </div>
    <div translate>Would you like to change your flight?</div>
  </div>
  <div class="buttons">
    <button
      class="navitaire-digital-button tertiary medium"
      [disabled]="false"
      (click)="keepDelayedFlight()"
      data-cy="keepDelayedFlightButton"
      [attr.aria-label]="'Keep this flight' | translate"
      translate
    >
      Keep this flight
    </button>
    <button
      class="navitaire-digital-button primary medium"
      [disabled]="false"
      (click)="changeDelayedFlight()"
      data-cy="changeDelayedFlightButton"
      [attr.aria-label]="'Change flight' | translate"
      translate
    >
      Change
    </button>
  </div>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.BOARDING_PASS"
  class="boarding-pass-wrapper for-boarding-pass-available"
>
  <div class="info-text" *ngIf="boardingPassesLoaded$ | async">
    <h3 class="time-message" translate>Boarding starts at</h3>
    <h3 class="time-info">
      {{
        _journeyKey
          | journeyKeyToJourney
          | journeyToBoardingTime$
          | async
          | localizedDate$ : 'H:mm'
          | async
          | lowercase
      }}
    </h3>
  </div>
</div>
<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.BOARDING_PASS"
  class="w100-button-wrapper for-boarding-pass-available"
>
  <button
    class="navitaire-digital-button primary"
    [disabled]="false"
    (click)="getBoardingPass()"
    data-cy="getBoardingPassButton"
    translate
    id="boardingPassButton"
  >
    <span class="icon icon-16 icon_Boarding-Pass-button"></span>
    Boarding Pass
  </button>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.FLIGHT_CLOSED"
  class="boarding-pass-wrapper for-boarding-pass-available"
>
  <div class="info-text" *ngIf="(checkedinPax$ | async).length > 0">
    <h3 class="time-message" translate>Flight Closed</h3>
  </div>
</div>
<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.FLIGHT_CLOSED"
  class="w100-button-wrapper for-boarding-pass-available"
>
  <button
    class="navitaire-digital-button primary"
    [disabled]="(checkedinPax$ | async).length === 0"
    (click)="getBoardingPassWithSomeCheckedInPax()"
    data-cy="getBoardingPassButton"
    translate
    id="boardingPassButton"
  >
    <span class="icon icon-16 icon_Boarding-Pass-button"></span>
    Boarding Pass
  </button>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.UPCOMING_CHECKIN"
  class="message-wrapper"
>
  <h3 class="time-message" translate>Check-in available in</h3>
  <h3 class="time-info">
    {{
      _journeyKey
        | journeyKeyToJourney
        | journeyDepartureTime
        | departureTimeToTimeToCheckin$
        | async
    }}
  </h3>
</div>
<!-- This can be used for future development-->
<!-- <div
  *ngIf="(displayOption$ | async) === displayOptionEnum.UPCOMING_CHECKIN"
  class="w100-button-wrapper"
>
  <button
    class="navitaire-digital-button"
    [disabled]="false"
    data-cy="rescheduleButton"
    translate
    id="rescheduleButton"
  >
    Reschedule
  </button>
</div> -->

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.CHECKIN_AVAILABLE"
  class="message-wrapper for-check-in-available"
>
  <h3 class="time-message" translate>Check-in is now available</h3>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.CHECKIN_AVAILABLE"
  class="w100-button-wrapper for-check-in-available"
>
  <button
    class="navitaire-digital-button"
    [disabled]="false"
    (click)="checkIn()"
    data-cy="checkinButton"
    translate
    id="checkinButton"
  >
    Check in
  </button>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.CANCELLED"
  class="message-wrapper"
>
  <h3 class="time-message" translate>Check-in no longer available.</h3>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.SUSPENDED"
  class="message-wrapper"
>
  <h3 class="time-message" translate>Check-in no longer available.</h3>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.CHECKIN_CLOSED"
  class="message-wrapper for-boarding-pass-available"
>
  <h3 class="time-message" translate>Check-in no longer available.</h3>
</div>
<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.CHECKIN_CLOSED"
  class="w100-button-wrapper for-boarding-pass-available"
>
  <button
    class="navitaire-digital-button primary"
    [disabled]="(checkedinPax$ | async).length === 0"
    (click)="getBoardingPassWithSomeCheckedInPax()"
    data-cy="getBoardingPassButton"
    translate
    id="boardingPassButton"
  >
    <span class="icon icon-16 icon_Boarding-Pass-button"></span>
    Boarding Pass
  </button>
</div>

<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.CONTACT_AGENT"
  class="message-wrapper"
>
  <h3 class="time-message" translate>
    Check-in is not available to you online.
  </h3>
  <h3 class="time-message" translate>Please contact an agent</h3>
</div>
<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.IN_FLIGHT"
  class="message-wrapper for-boarding-pass-available"
>
  <h3 class="time-message" translate>Flight in progress</h3>
</div>
<div
  *ngIf="(displayOption$ | async) === displayOptionEnum.IN_FLIGHT"
  class="w100-button-wrapper for-boarding-pass-available"
>
  <button
    class="navitaire-digital-button primary"
    [disabled]="(checkedinPax$ | async).length === 0"
    (click)="getBoardingPassWithSomeCheckedInPax()"
    data-cy="getBoardingPassButton"
    translate
    id="boardingPassButton"
  >
    <span class="icon icon-16 icon_Boarding-Pass-button"></span>
    Boarding Pass
  </button>
</div>
<div
  *ngIf="
    (displayOption$ | async) === displayOptionEnum.EXTERNAL_CARRIER_CHECKIN
  "
  class="message-wrapper"
>
  <h3 class="time-message">
    <span translate
      >Check-in for this flight needs to be completed through the operating
      airline</span
    >
    <ng-container *ngIf="(externalLocator$ | async) !== undefined">
      ,
      {{
        (externalLocator$ | async)?.owningSystemCode | carrierCodeToName
      }},&nbsp;<span translate>using this Booking ID</span>:
      {{ (externalLocator$ | async)?.recordCode }}
    </ng-container>
  </h3>
</div>
