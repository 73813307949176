import { Component, ViewEncapsulation,OnInit } from '@angular/core';
import { BaggageAllowance, BoardingPassesCollectionv2,Passenger } from '@navitaire-digital/nsk-api-4.5.0';
import { CheckinDataService } from '@navitaire-digital/web-data-4.5.0';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import type { Dictionary } from 'lodash';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { QGCheckinDataService, BookingsDataService } from '@customer/extensions';
import { WindowRefService } from '../../../common/window-ref.service';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { selectAllJourneysBoardingPasses,selectJourneyBoardingPassSelected,selectBoardingPassClicked,selectPassengerBoardingPassSelected } from '../../../store';
import { BoadingPassService } from '../../../../../../extensions/src/lib/services/citilink-api';

@Component({
  selector: 'navitaire-digital-boarding-pass-page',
  templateUrl: './boarding-pass-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['boarding-pass-page.scss']
})
export class BoardingPassPageComponent implements OnInit {
  isModalBoardingPassClicked$:Observable<boolean> = this.store.select(selectBoardingPassClicked);
  journeyKeySelected$:Observable<string> = this.store.select(selectJourneyBoardingPassSelected);
  passengerKeySelected$:Observable<string> = this.store.select(selectPassengerBoardingPassSelected);
  boardingPasses$: Dictionary<BoardingPassesCollectionv2>;
  passengers$: Observable<Passenger[]> =  this.checkinDataService.passengers$;
  baggageAllowance$: BaggageAllowance[] = [];

  constructor(
    protected checkinDataService: CheckinDataService,
    protected store: Store,
    protected windowRefService: WindowRefService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected qgCheckinDataService: QGCheckinDataService,
    protected bookingsDataService: BookingsDataService,
    protected boadingPassService: BoadingPassService
  ) {}

  async ngOnInit(): Promise<void>{
    await this.getBaggageAllowance();
    
    var paxKeySelected = getObservableValueSync(this.passengerKeySelected$);
    var journeyKeySelected = getObservableValueSync(this.journeyKeySelected$);
    var isModalBoardingPassClicked = getObservableValueSync(this.isModalBoardingPassClicked$);
    let checkedinPassengersBoardingPasses: Dictionary<BoardingPassesCollectionv2> = {};
    var paxKeyArray:string[] = [];
    if(paxKeySelected){
      paxKeyArray.push(paxKeySelected);    
      checkedinPassengersBoardingPasses[journeyKeySelected] 
       = await this.qgCheckinDataService.fetchBoardingPassesV2(paxKeyArray, journeyKeySelected);  
      this.boardingPasses$ = checkedinPassengersBoardingPasses;
      if(!isModalBoardingPassClicked && this.boardingPasses$){
        this.print();
      }
    }
    else{
      this.boardingPasses$ = getObservableValueSync(this.store.select(selectAllJourneysBoardingPasses));
      if(!isModalBoardingPassClicked && this.boardingPasses$){
        this.print();
      }
    }
  }

  closeOverlay(): void {
    this.overlayService.hide();
  }

  print(): void {
    if (this.windowRefService.window) {
      setTimeout(() => {
        this.windowRefService.window.print();
      }, 250);
      
    }
  }

  async getBaggageAllowance(): Promise<void>{
    var paxKeySelected = getObservableValueSync(this.passengerKeySelected$);
    var journeyKeySelected = getObservableValueSync(this.journeyKeySelected$);
    var response = await this.bookingsDataService.getBaggageAllowance();
    var data = response.body.data;

    for(var i in data){
      const passengerKey = data[i];

      for(var ii in passengerKey){
        const passengerAllowance = passengerKey[ii];

        if(passengerAllowance.journeyKey == journeyKeySelected && passengerAllowance.passengerKey == paxKeySelected ){
          const item: BaggageAllowance = {
            journeyKey : passengerAllowance.journeyKey,
            passengerKey: passengerAllowance.passengerKey,
            totalWeight: passengerAllowance.totalWeight
          }
  
          this.baggageAllowance$.push(item);
        }
      }
    }
  }
}
