<div class="payment-method-page-container">
  <div class="column">
    <div class="panel-header">
      <h4 class="panel-header-category" translate>
        {{ category }}
      </h4>
    </div>

    <div class="content-wrapper">
      <div class="details-wrapper">
        <div class="payment-method-logo">
          <div class="logo" [class]="iconClass"></div>
        </div>
        <div class="payment-method-name" translate>
          {{ paymentName }}
        </div>
      </div>

      <div class="price-wrapper">
        <div class="price">
          <h4 translate>Price</h4>
          <h4>
            {{
              total$
                | async
                | currency : (currencyCode$ | async) : 'symbol-narrow'
                | removeDoubleZeroesCurrency
            }}
          </h4>
        </div>
        <div class="price" *ngIf="serviceFee > 0">
          <h4 translate>Service Fee</h4>
          <h4>
            {{
              serviceFee
                | currency : (currencyCode$ | async) : 'symbol-narrow'
                | removeDoubleZeroesCurrency
            }}
          </h4>
        </div>
        <div class="total">
          <h4 translate>Total Amount</h4>
          <h4>
            {{
              totalDisplayPrice
                | currency : (currencyCode$ | async) : 'symbol-narrow'
                | removeDoubleZeroesCurrency
            }}
          </h4>
        </div>
      </div>

      <div class="information-wrapper">
        <h4 class="title" translate>Important Information</h4>
        <h5 class="text-disabled description">
          <ul>
            <li translate>
              payment.{{ paymentName }}.desc1
            </li>
            <li translate>
              payment.{{ paymentName }}.desc2
            </li>
          </ul>
        </h5>
      </div>
      <div class="separator"></div>

      <div class="terms-wrapper">
        <div class="icon icon_Verified"></div>
        <h5 class="description" translate>
          payment.agreement
        </h5>
      </div>

      <div class="action-wrapper">
        <button
          tabindex="0"
          [attr.aria-label]="'Pay With ' + paymentName + 'paywith'| translate"
          class="navitaire-digital-button primary small"
          (click)="submitPayment()"
          translate
        >
        payment.{{ paymentName }}.paywith
        </button>
      </div>
    </div>
  </div>
</div>

<navitaire-digital-booking-summary-overview
  navitaireDigitalBookingSummaryView
  class="booking-summary-section"
  [origin]="isManageFlow ? selectedJourneyOrigin : (origin$ | async)"
  [destination]="isManageFlow ? selectedJourneyDestination : (destination$ | async)"
  [tripType]="tripType$ | async"
  [showPriceDetails]="displayPriceDetails"
  [showFlightDetails]="displayFlightDetails"
  [showPassengerDetails]="displayPassengerDetails"
  [displayType]="bookingFlow"
  [serviceFeeAmount]="displayBookingSummaryServiceFee | async"
></navitaire-digital-booking-summary-overview>
